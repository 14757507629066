import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ButtonDirective } from '../../../directives/button.directive';
import { TitleDirective } from '../../../directives/title.directive';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-pricing-card',
  standalone: true,
  imports: [CommonModule, ButtonDirective, TitleDirective, RouterLink],
  templateUrl: './pricing-card.component.html',
  styleUrl: './pricing-card.component.scss',
})
export class PricingCardComponent {
  @Input() topText?: string;
  @Input() topTabText?: string;
  @Input() cardColor = '#eef3e3';
  @Input() title?: string;
  @Input() subTitle?: string;
  @Input() price!: string;
  @Input() includedItems: string[] = [
    'Gut reset programme (8 weeks)',
    'Genetic information synced with your nutrient needs',
    'Recipe recommendations',
    'Daily goals',
    '£10 donation to charity of your choice',
  ];

  @Input() bottomCardContent?: string;
  @Input() bottomCardAccent?: string;

  @Input() buttonColor: 'primary' | 'white' | 'light-green' | 'outline-orange' = 'primary';
  @Input() buttonText: string = 'Activate 7 day trial';
  @Input() buttonLink?: string = 'https://join.revivedna.com';

  navTo(path: string) {
    window.location.assign(path);
  }
}
