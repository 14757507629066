export const improveMySleepData = {
  title: 'Support my sleep',
  titleAccent: 'sleep',
  heroContent:
    'Revive utilizes DNA analysis to offer personalised insights aimed at enhancing sleep quality. By examining individual genetic markers related to sleep, such as circadian rhythm regulation and nutrient metabolism, Revive tailors recipes to each individual.',
  areaOverview: {
    title: 'Overview',
    contentSections: [
      'Through comprehensive genetic profiling, users gain insights into their unique predispositions towards certain nutrients and how they may impact sleep quality. For instance, variations in genes associated with melatonin production or caffeine metabolism can influence sleep onset and duration. With this information, Revive can provide dietary insights that can be used as part of a holistic look at health.',
      'Moreover, Revive identifies potential nutrient deficiencies or imbalances that may disrupt sleep patterns, such as deficiencies in magnesium or vitamin D, both crucial for regulating sleep. Tailored dietary recommendations aim to address these deficiencies, promoting better sleep hygiene and overall health.',
      'By leveraging the power of genetic insights, Revive empowers individuals to make informed dietary choices aligned with their unique genetic makeup, ultimately aiming to foster improved sleep quality and overall well-being.',
    ],
  },
  signUp: {
    content:
      'We send regular free tips and the latest scientific advice on how to help manage your ongoing symptoms.',
    options: [
      'Nutrition',
      'Mental health',
      'Sleep',
      'Immunity',
      'Gut health',
      'Energy',
    ],
    imgSrc: '/assets/img/how-we-can-help-2.png',
  },
};
