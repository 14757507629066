<app-hero-text-only>
  <h1 header class="color-white" appTitle accent="Contact" accentColor="white">
    Contact us
  </h1>
  <p class="color-white">
    We would love to hear from you. Please contact us via the links below.
  </p>
</app-hero-text-only>
<app-content-card style="margin: -40px 0; z-index: +1">
  <h2
    appTitle
    accent="help you?"
    accentColor="#3e8364"
    style="text-align: center"
  >
    How can we help you?
  </h2>

  <div class="cards">
    <div *ngFor="let option of contactOptions" class="contact-card">
      <img [src]="option.icon" />

      <h4>{{ option.title }}</h4>

      <a [href]="'mailto:' + option.link">{{ option.link }}</a>
    </div>
  </div>
</app-content-card>
<app-content-card style="background-color: white">
  <div class="contact-form-section">
    <section>
      <h2 appTitle accent="anything" accentColor="#3e8364">Ask us anything</h2>
      <p>If you want to learn more, please get in contact below.</p>
    </section>
    <form [formGroup]="formGroup">
      <div class="input-container">
        <label>Name *</label>
        <input
          type="text"
          formControlName="name"
          placeholder="Enter your name"
        />
      </div>
      <div class="input-container">
        <label>Email *</label>
        <input
          type="email"
          formControlName="email"
          placeholder="Enter your email address"
        />
      </div>
      <div class="input-container">
        <label>Phone</label>
        <input
          type="tel"
          formControlName="phone"
          placeholder="Enter your phone number"
        />
      </div>
      <div class="input-container">
        <label>Message *</label>
        <textarea
          formControlName="message"
          placeholder="Enter your message"
          rows="4"
        >
        </textarea>
      </div>

      <button appButton>
        <img src="/assets/img/arrow-right-circle-white.svg" />
        Send request
      </button>
    </form>
  </div>
</app-content-card>
<app-get-started-section></app-get-started-section>
