import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TitleDirective } from '../../directives/title.directive';
import {
  CustomerFeedback,
  FeedbackCardComponent,
} from './feedback-card/feedback-card.component';

@Component({
  selector: 'app-customer-feedback',
  standalone: true,
  imports: [CommonModule, TitleDirective, FeedbackCardComponent],
  templateUrl: './customer-feedback.component.html',
  styleUrl: './customer-feedback.component.scss',
})
export class CustomerFeedbackComponent {
  public feedbackItems: CustomerFeedback[] = [
    // {
    //   title: 'A game changer',
    //   content:
    //     'Revive are innovating a marriage between applied technology and cutting edge immune centric protocols. They are at the forefront of understanding how things really work when it comes to nutrition.”',
    //   headShotSrc: '/assets/img/feedback/feedback-joel.jpeg',
    //   customerName: 'Joel Greene',
    //   customerSubtext: 'CEO and Author of “The Immunity Code” and “The Way”',
    // },
    {
      title: 'Leading the way',
      content:
        '"Data-driven health management is the best approach to optimizing your health and performance. It is the process of utilizing your genetic make-up, lab results, symptoms and available scientific information to manage your health. Revive is a great example of a solution that is leading the way in utilising the latest technology available in genetic analysis to help improve the health outcomes of their clients all around the world"',
      headShotSrc: '/assets/img/feedback/feedback-joe.jpeg',
      customerName: 'Joe Cohen',
      customerSubtext: 'CEO of SelfDecode',
    },
    {
      title: `It's all about you`,
      content: `“I would say that everybody would benefit from this as it's all about you and the app making sure it's about you. It uses the information from the DNA test to make it specific to me."`,
      headShotSrc: '/assets/img/feedback/feedback-ceil.png',
      customerName: 'Ceil',
      customerSubtext: 'Revive User',
    },
    {
      title: `It's changed my life`,
      content: `“Revive makes it so easy to plan out what I should be eating. The shopping list feature makes it easy to order everything I need for the week which stops me eating things which I now know cause me issues. The DNA test results are so detailed and I have learnt so much about my body."`,
      headShotSrc: '/assets/img/feedback/feedback-gary.jpg',
      customerName: 'Gary',
      customerSubtext: 'Revive User',
    },
  ];
}
