import { Component } from '@angular/core';
import { ButtonDirective } from '../../directives/button.directive';
import { TitleDirective } from '../../directives/title.directive';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-get-started-section',
  standalone: true,
  imports: [ButtonDirective, TitleDirective, RouterLink],
  templateUrl: './get-started-section.component.html',
  styleUrl: './get-started-section.component.scss',
})
export class GetStartedSectionComponent {
  navTo(path: string) {
    window.location.assign(path);
  }
}
