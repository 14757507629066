import { Component } from '@angular/core';
import { CardsGridTwoComponent } from '../../components/cards-grid-two/cards-grid-two.component';
import { CardsGridComponent } from '../../components/cards-grid/cards-grid.component';
import { CardsSectionComponent } from '../../components/cards-section/cards-section.component';
import { ExpansionCardsSectionComponent } from '../../components/expansion-cards-section/expansion-cards-section.component';
import { GetStartedSectionComponent } from '../../components/get-started-section/get-started-section.component';
import { HeroComponent } from '../../components/hero/hero.component';
import { TitleAndTextSectionComponent } from '../../components/title-and-text-section/title-and-text-section.component';
import { PricingSectionComponent } from '../../components/pricing-section/pricing-section.component';
import { ImageAndTextCardSectionComponent } from '../../components/image-and-text-card-section/image-and-text-card-section.component';
import { TitleDirective } from '../../directives/title.directive';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    HeroComponent,
    CardsSectionComponent,
    CardsGridComponent,
    CardsGridTwoComponent,
    TitleAndTextSectionComponent,
    ImageAndTextCardSectionComponent,
    ExpansionCardsSectionComponent,
    GetStartedSectionComponent,
    PricingSectionComponent,
    TitleDirective,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent {}
