<app-hero-text-only>
  <h1 header class="color-white" appTitle accent="Privacy" accentColor="white">
    Privacy Policy
  </h1>
</app-hero-text-only>
<app-content-card style="margin-top: -40px; background-color: white">
  <h4>Who We Are</h4>

  <p>
    At Progress Health and Wellness Ltd. we gather and process your personal
    information in accordance with this privacy policy and in compliance with
    the relevant data protection regulations and laws. This notice provides you
    with the necessary information regarding your rights and our obligations,
    and explains how, why and when we process your personal data.
    <br />
    Progress Health and Wellness Ltd. registered office is Suite 93, 89
    Commercial Road, Bournemouth, Dorset, England, BH2 5RR and we are a company
    registered in England and Wales under company number 13361824.
  </p>

  <h4>Information That We Collect</h4>

  <p>
    Progress Health and Wellness Ltd. processes your personal information to
    meet our legal, statutory and contractual obligations and to provide you
    with our products and services. We will never collect any unnecessary
    personal data from you and do not process your information in any way, other
    than as specified in this notice.
  </p>

  <p>The personal data that we may collect from you include:</p>

  <ul>
    <li>Title / Name</li>
    <li>Date of Birth</li>
    <li>Home Address</li>
    <li>Personal Email / Business Email</li>
    <li>Sex</li>
    <li>
      Home Telephone Number / Mobile Telephone Number / Work Telephone Number
    </li>
    <li>Work Title</li>
    <li>Employer</li>
    <li>Referral Source</li>
    <li>
      Special Category Data (i.e. health/medical information, work history,
      hobbies, family background)
    </li>
    <li>GP / Consultant Details</li>
    <li>Credit and debit card details</li>
  </ul>

  <p>We collect information in the following ways:</p>

  <ul>
    <li>
      Website 'Contact Us' form and "sign up to our newsletter" on the
      <a href="https://revivedna.com/"></a> landing page.
    </li>
    <li>
      Email correspondence (either from you or referral from GP / Consultant or
      employer)
    </li>
  </ul>

  <h4>Your Rights</h4>

  <p>
    You have the right to access any personal information that Progress Health
    and Wellness Ltd. processes about you and to request information about:
  </p>

  <ul>
    <li>What personal data we hold about you</li>
    <li>The purposes of the processing</li>
    <li>The categories of personal data concerned</li>
    <li>The recipients to whom the personal data has/will be disclosed</li>
    <li>How long we intend to store your personal data for</li>
    <li>
      If we did not collect the data directly from you, information about the
      source
    </li>
  </ul>

  <p>
    f you believe that we hold any incomplete or inaccurate data about you, you
    have the right to ask us to correct and/or complete the information and we
    will strive to do so as quickly as possible; unless there is a valid reason
    for not doing so, at which point you will be notified.
  </p>

  <p>
    You also have the right to request erasure of your personal data or to
    restrict processing (where applicable) in accordance with the data
    protection laws; as well as to object to any direct marketing from us. Where
    applicable, you have the right to data portability of your information.
  </p>

  <p>
    If we receive a request from you to exercise any of the above rights, this
    is to ensure that your data is protected and kept secure. We may ask you to
    verify your identity before acting on the request via:
  </p>

  <ul>
    <li>Face to face consultation, in person or online</li>
    <li>Social media (i.e. Facebook, Twitter, Instagram, LinkedIn)</li>
  </ul>

  <h4>Sharing and Disclosing Your Personal Information</h4>

  <p>
    We do not share or disclose any of your personal information without your
    consent, other than for the purposes specified in this notice or where there
    is a legal requirement. Progress Health and Wellness Ltd. uses third-parties
    to provide the below services and business functions; however, all
    processors acting on our behalf only process your data in accordance with
    instructions from us and comply fully with this privacy notice, the data
    protection laws and any other appropriate confidentiality and security
    measures.
  </p>

  <h4>Selfdecode Inc, OmicsEdge Inc</h4>

  <p>
    We use <a href="https://selfdecode.com" target="_blank">selfdecode</a> and
    <a href="https://omicsedge.com/" target="_blank">omics</a> edge to analyse
    your genetic data. Data in relation to your DNA and your account information
    is stored here.
  </p>

  <h4>Safeguarding Measures</h4>

  <p>
    We take your privacy seriously and takes every reasonable measure and
    precaution to protect and secure your personal data. We work hard to protect
    you and your information from unauthorised access, alteration, disclosure or
    destruction and have several layers of security measures in place,
    including:
  </p>

  <ul>
    <li>Restricted access / IT authentication</li>
    <li>Firewalls</li>
    <li>Anti-Virus / Malware</li>
    <li>Email encryption</li>
  </ul>

  <p>
    Progress Health and Wellness Ltd. utilise some products or services (or
    parts of them) that may be hosted/stored in non-EU countries, which means
    that we may transfer any information which is submitted by you outside the
    European Economic Area (“EEA”) for the below purposes:
  </p>

  <ul>
    <li>Website hosting</li>
    <li>Email servers</li>
    <li>Marketing database</li>
  </ul>

  <p>
    Therefore, when you use our website, send us an email, sign up to our
    newsletter etc… the personal information you submit may be stored on servers
    which are hosted in non-EU countries. Where this is the case, we will take
    steps to ensure that those providers use the necessary level of protection
    for your information and abide by strict agreements and measures set out by
    Progress Health and Wellness Ltd. to protect your data and comply with the
    relevant data protection laws.
  </p>

  <h4>Consequences of Not Providing Your Data</h4>

  <p>
    You are not obligated to provide your personal information to Progress
    Health and Wellness Ltd. however, as this information is required for us to
    provide you with our clinical services, we will not be able to offer our
    services without it.
  </p>

  <h4>How Long We Keep Your Data</h4>

  <p>
    Progress Health and Wellness Ltd. only ever retains personal information for
    as long as is necessary and we have strict review and retention policies in
    place to meet these obligations. We are required under UK tax law to keep
    your basic personal data (name, address, contact details) for a minimum of 6
    years, after which time it will be destroyed.
    <br />
    Where you have consented to us using your details for direct marketing, we
    will keep such data until you notify us otherwise and/or withdraw your
    consent.
  </p>

  <h4>Special Categories Data</h4>

  <p>
    Owing to the services and treatments that we offer, Progress Health and
    Wellness Ltd. will need to process sensitive personal information (known as
    special category data) about you, to ensure safe and effective treatment can
    take place. Where we collect such information, we will only request and
    process the minimum necessary for the specified purpose and identify a
    compliant legal basis for doing so.
    <br />
    Where we rely on your consent for processing special category data, we will
    obtain your explicit consent through a signature at your first consultation.
    You can modify or withdraw consent at any time, which we will act on
    immediately, unless there is a legitimate or legal reason for not doing so.
  </p>

  <h4>Lodging A Complaint</h4>

  <p>
    Progress Health and Wellness Ltd. only processes your personal information
    in compliance with this privacy notice and in accordance with the relevant
    data protection laws. If, however you wish to raise a complaint regarding
    the processing of your personal data or are unsatisfied with how we have
    handled your information, you have the right to lodge a complaint with the
    supervisory authority.
  </p>

  <p>
    Progress Health and Wellness Ltd.
    <br />
    Suite 93, 89 Commercial Road, Bournemouth, Dorset, England, BH2 5RR
    <br />
    info&#64;revivedna.com
    <br />
    Information Commissioner's Office
    <br />
    Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF
    <br />
    0303 123 1113 /
    <a href="https://ico.org.uk/global/contact-us/email/" target="_blank"
      >https://ico.org.uk/global/contact-us/email/</a
    >
  </p>

  <h4>Consent</h4>
  <p>
    We take your privacy seriously and will only process your personal data with
    your consent and in accordance with the terms stated in our Privacy Notice.
    We will obtain your explicit consent through a signature at your first
    face-to-face consultation. You can modify or withdraw consent at any time,
    which we will act on immediately, unless there is a legitimate or legal
    reason for not doing so.
  </p>
</app-content-card>
<app-get-started-section></app-get-started-section>
