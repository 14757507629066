import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ButtonDirective } from '../../../directives/button.directive';
import { TitleDirective } from '../../../directives/title.directive';
import {
  PhaseDetailDialogComponent,
  PhaseDetailDialogData,
} from '../../../dialogs/phase-detail-dialog/phase-detail-dialog.component';
import { Dialog, DialogModule } from '@angular/cdk/dialog';

export interface PhaseCardData {
  small: string;
  indicatorOpacity: string;
  title: string;
  titleAccent: string;
  content: string;
  dialogContent: PhaseDetailDialogData;
}

@Component({
  selector: 'app-phase-card',
  standalone: true,
  imports: [
    CommonModule,
    ButtonDirective,
    TitleDirective,
    PhaseDetailDialogComponent,
    DialogModule,
  ],
  templateUrl: './phase-card.component.html',
  styleUrl: './phase-card.component.scss',
})
export class PhaseCardComponent {
  @Input() data!: PhaseCardData;

  constructor(private dialog: Dialog) {}

  openDetail(data: PhaseDetailDialogData) {
    PhaseDetailDialogComponent.open(this.dialog, data);
  }
}
