<div class="hero-content">
  <h1 class="color-white">
    <span class="accent">Precision</span>
    nutrition powered by <span class="accent">AI</span>
  </h1>
  <p class="color-white">Everything we do is all about you</p>
  <div style="display: flex; flex-direction: column; gap: 12px">
    <button
      appButton
      color="white"
      (click)="navTo('https://join.revivedna.com')"
    >
      <img src="/assets/img/arrow-right-circle-orange.svg" />
      Optimise my health
    </button>
    <button appButton color="white" [routerLink]="['/chronic-pain']">
      <img src="/assets/img/arrow-right-circle-orange.svg" />
      Manage my pain
    </button>
  </div>
</div>
<div class="hero-img">
  <div class="hero-mask"></div>
</div>
<img class="desktop-hero-img" src="/assets/img/desktop-hero-1.png" />
