<div class="header" style="display: flex">
  <!-- <div class="title">{{ title }}</div> -->
  <h3>{{ title }}</h3>
  <img
    style="margin-left: auto"
    [src]="
      open
        ? '/assets/icons/circle-chevron-green.svg'
        : '/assets/icons/circle-chevron-green-transparent.svg'
    "
    (click)="open = !open"
  />
</div>
<p *ngIf="open" [innerHTML]="htmlContent">
  <!-- Everything we do is based on using the most up to date scientific data. We
  combine cutting-edge biochemistry research with your unique genetic code, to
  give you a comprehensive explanation of the "why" behind our recommendations.
  These insights pave the way for you to conduct your own research to learn more
  about your individuality and discover additional areas to optimise your
  health. -->
</p>
