<app-hero-text-only>
  <h1 header class="color-white" appTitle accent="Terms" accentColor="white">
    Terms and Conditions
  </h1>
</app-hero-text-only>
<app-content-card style="margin-top: -40px; background-color: white">
  <h4>1. Introduction</h4>

  <p>
    1.1 These terms and conditions shall govern your use of our website.
    <br />
    1.2 By using our website, you accept these terms and conditions in full;
    accordingly, if you disagree with these terms and conditions or any part of
    these terms and conditions, you must not use our website.
    <br />
    1.3 If you [register with our website, submit any material to our website or
    use any of our website services], we will ask you to expressly agree to
    these terms and conditions.
    <br />
    1.4 You must be at least 18 years of age to use our website; and by using
    our website or agreeing to these terms and conditions, you warrant and
    represent to us that you are at least 18 years of age.
    <br />
    1.5 Our website uses cookies; by using our website or agreeing to these
    terms and conditions, you consent to our use of cookies in accordance with
    the terms of our privacy and cookies policies.
  </p>

  <h4>2. Credit</h4>
  <p>
    2.1 This document was created using a template from SEQ Legal
    (http://www.seqlegal.com).
  </p>

  <h4>3. Copyright notice</h4>
  <p>
    3.1 © 2024 Progress Health and Wellness Ltd.
    <br />
    3.2 Subject to the express provisions of these terms and conditions:
    <br />
    (a) we, together with our licensors, own and control all the copyright and
    other intellectual property rights in our website and the material on our
    website; and
    <br />
    (b) all the copyright and other intellectual property rights in our website
    and the material on our website are reserved.
  </p>

  <h4>4. Licence to use website</h4>
  <p>
    4.1 You may:
    <br />
    (a) view pages from our website in a web browser;
    <br />
    (b) download pages from our website for caching in a web browser;
    <br />
    (c) print pages from our website;
    <br />
    (d) stream audio and video files from our website; and
    <br />
    (e) use our website services by means of a web browser,subject to the other
    provisions of these terms and conditions.
    <br />
    4.2 Except as expressly permitted by Section 4.1 or the other provisions of
    these terms and conditions, you must not download any material from our
    website or save any such material to your computer.
    <br />
    4.3 You may only use our website for your own personal and business
    purposes, and you must not use our website for any other purposes.
    <br />
    4.4 Except as expressly permitted by these terms and conditions, you must
    not edit or otherwise modify any material on our website.
    <br />
    4.5 Unless you own or control the relevant rights in the material, you must
    not:
    <br />
    (a) republish material from our website (including republication on another
    website);
    <br />
    (b) sell, rent or sub-license material from our website;
    <br />
    (c) show any material from our website in public;
    <br />
    (d) exploit material from our website for a commercial purpose; or
    <br />
    (e) redistribute material from our website.
    <br />
    4.6 We reserve the right to restrict access to areas of our website, or
    indeed our whole website, at our discretion; you must not circumvent or
    bypass, or attempt to circumvent or bypass, any access restriction measures
    on our website.
  </p>

  <h4>5. Acceptable use</h4>

  <p>
    5.1 You must not:
    <br />
    (a) use our website in any way or take any action that causes, or may cause,
    damage to the website or impairment of the performance, availability or
    accessibility of the website;
    <br />
    (b) use our website in any way that is unlawful, illegal, fraudulent or
    harmful, or in connection with any unlawful, illegal, fraudulent or harmful
    purpose or activity;
    <br />
    (c) use our website to copy, store, host, transmit, send, use, publish or
    distribute any material which consists of (or is linked to) any spyware,
    computer virus, Trojan horse, worm, keystroke logger, rootkit or other
    malicious computer software;
    <br />
    (d) conduct any systematic or automated data collection activities
    (including without limitation scraping, data mining, data extraction and
    data harvesting) on or in relation to our website without our express
    written consent;
    <br />
    (e) access or otherwise interact with our website using any robot, spider or
    other automated means unless authorised;
    <br />
    (f) violate the directives set out in the robots.txt file for our website;
    or
    <br />
    (g) use data collected from our website for any direct marketing activity
    (including without limitation email marketing, SMS marketing, telemarketing
    and direct mailing).
    <br />
    5.2 You must not use data collected from our website to contact individuals,
    companies or other persons or entities.
    <br />
    5.3 You must ensure that all the information you supply to us through our
    website, or in relation to our website, is true, accurate, current, complete
    and non-misleading.
  </p>

  <h4>6. Your content: licence</h4>

  <p>
    6.1 In these terms and conditions, “your content” means all works and
    materials (including without limitation text, graphics, images, audio
    material, video material, audio-visual material, scripts, software and
    files) that you submit to us or our website for storage or publication on,
    processing by, or transmission via, our website.
    <br />
    6.2 You grant to us a worldwide, irrevocable, non-exclusive, royalty-free
    licence to use, reproduce, store, adapt, publish, translate and distribute
    your content in any existing or future media / reproduce, store and publish
    your content on and in relation to this website and any successor website /
    reproduce, store and, with your specific consent, publish your content on
    and in relation to this website.
    <br />
    6.3 You grant to us the right to sub-license the rights licensed under
    Section 6.2.
    <br />
    6.4 You grant to us the right to bring an action for infringement of the
    rights licensed under Section 6.2.
    <br />
    6.5 You hereby waive all your moral rights in your content to the maximum
    extent permitted by applicable law; and you warrant and represent that all
    other moral rights in your content have been waived to the maximum extent
    permitted by applicable law.
    <br />
    6.6 You may edit your content to the extent permitted using the editing
    functionality made available on our website.
    <br />
    6.7 Without prejudice to our other rights under these terms and conditions,
    if you breach any provision of these terms and conditions in any way, or if
    we reasonably suspect that you have breached these terms and conditions in
    any way, we may delete, unpublish or edit any or all of your content.
  </p>

  <h4>7. Your content: rules</h4>
  <p>
    7.1 You warrant and represent that your content will comply with these terms
    and conditions.
    <br />
    7.2 Your content must not be illegal or unlawful, must not infringe any
    person's legal rights, and must not be capable of giving rise to legal
    action against any person (in each case in any jurisdiction and under any
    applicable law).
    <br />
    7.3 Your content, and the use of your content by us in accordance with these
    terms and conditions, must not:
    <br />
    (a) be libellous or maliciously false;
    <br />
    (b) be obscene or indecent;
    <br />
    (c) infringe any copyright, moral right, database right, trade mark right,
    design right, right in passing off, or other intellectual property right;
    <br />
    (d) infringe any right of confidence, right of privacy or right under data
    protection legislation;
    <br />
    (e) constitute negligent advice or contain any negligent statement;
    <br />
    (f) constitute an incitement to commit a crime[, instructions for the
    commission of a crime or the promotion of criminal activity;
    <br />
    (g) be in contempt of any court, or in breach of any court order;
    <br />
    (h) be in breach of racial or religious hatred or discrimination
    legislation;
    <br />
    (i) be blasphemous;
    <br />
    (j) be in breach of official secrets legislation;
    <br />
    (k) be in breach of any contractual obligation owed to any person;
    <br />
    (l) depict violence[, in an explicit, graphic or gratuitous manner;
    <br />
    (m) be pornographic, lewd, suggestive or sexually explicit;
    <br />
    (n) be untrue, false, inaccurate or misleading;
    <br />
    (o) consist of or contain any instructions, advice or other information
    which may be acted upon and could, if acted upon, cause illness, injury or
    death, or any other loss or damage;
    <br />
    (p) constitute spam;
    <br />
    (q) be offensive, deceptive, fraudulent, threatening, abusive, harassing,
    anti-social, menacing, hateful, discriminatory or inflammatory; or
    <br />
    (r) cause annoyance, inconvenience or needless anxiety to any person.
  </p>

  <h4>8. Limited warranties</h4>
  <p>
    8.1 We do not warrant or represent:
    <br />
    (a) the completeness or accuracy of the information published on our
    website;
    <br />
    (b) that the material on the website is up to date; or
    <br />
    (c) that the website or any service on the website will remain available.
    <br />
    8.2 We reserve the right to discontinue or alter any or all of our website
    services, and to stop publishing our website, at any time in our sole
    discretion without notice or explanation; and save to the extent expressly
    provided otherwise in these terms and conditions, you will not be entitled
    to any compensation or other payment upon the discontinuance or alteration
    of any website services, or if we stop publishing the website.
    <br />
    8.3 To the maximum extent permitted by applicable law and subject to Section
    9.1, we exclude all representations and warranties relating to the subject
    matter of these terms and conditions, our website and the use of our
    website.
  </p>

  <h4>9. Limitations and exclusions of liability</h4>
  <p>
    9.1 Nothing in these terms and conditions will:
    <br />
    (a) limit or exclude any liability for death or personal injury resulting
    from negligence; (b) limit any liabilities in any way that is not permitted
    under applicable law; or
    <br />
    (b) exclude any liabilities that may not be excluded under applicable law.
    <br />
    9.2 The limitations and exclusions of liability set out in this Section 12
    and elsewhere in these terms and conditions:
    <br />
    (a) are subject to Section 9.1; and
    <br />
    (b) govern all liabilities arising under these terms and conditions or
    relating to the subject matter of these terms and conditions, including
    liabilities arising in contract, in tort (including negligence) and for
    breach of statutory duty.
    <br />
    9.3 To the extent that our website and the information and services on our
    website are provided free of charge, we will not be liable for any loss or
    damage of any nature.
    <br />
    9.4 We will not be liable to you in respect of any losses arising out of any
    event or events beyond our reasonable control.
    <br />
    9.5 We will not be liable to you in respect of any business losses,
    including (without limitation) loss of or damage to profits, income,
    revenue, use, production, anticipated savings, business, contracts,
    commercial opportunities or goodwill.
    <br />
    9.6 We will not be liable to you in respect of any loss or corruption of any
    data, database or software.
    <br />
    9.7 We will not be liable to you in respect of any special, indirect or
    consequential loss or damage.
    <br />
    9.8 You accept that we have an interest in limiting the personal liability
    of our officers and employees and, having regard to that interest, you
    acknowledge that we are a limited liability entity; you agree that you will
    not bring any claim personally against our officers or employees in respect
    of any losses you suffer in connection with the website or these terms and
    conditions (this will not, of course, limit or exclude the liability of the
    limited liability entity itself for the acts and omissions of our officers
    and employees).
  </p>

  <h4>10. Breaches of these terms and conditions</h4>
  <p>
    10.1 Without prejudice to our other rights under these terms and conditions,
    if you breach these terms and conditions in any way, or if we reasonably
    suspect that you have breached these terms and conditions in any way, we
    may:
    <br />
    (a) send you one or more formal warnings;
    <br />
    (b) temporarily suspend your access to our website;
    <br />
    (c) permanently prohibit you from accessing our website;
    <br />
    (d) block computers using your IP address from accessing our website;
    <br />
    (e) contact any or all your internet service providers and request that they
    block your access to our website;
    <br />
    (f) commence legal action against you, whether for breach of contract or
    otherwise; and/or
    <br />
    (g) suspend or delete your account on our website.
    <br />
    10.2 Where we suspend or prohibit or block your access to our website or a
    part of our website, you must not take any action to circumvent such
    suspension or prohibition or blocking (including without limitation creating
    and/or using a different account).
  </p>

  <h4>11. Variation</h4>
  <p>
    11.1 We may revise these terms and conditions from time to time.
    <br />
    11.2 The revised terms and conditions shall apply to the use of our website
    from the date of publication of the revised terms and conditions on the
    website, and you hereby waive any right you may otherwise have to be
    notified of, or to consent to, revisions of these terms and conditions.
  </p>

  <h4>12. Assignment</h4>
  <p>
    12.1 You hereby agree that we may assign, transfer, sub-contract or
    otherwise deal with our rights and/or obligations under these terms and
    conditions.
    <br />
    12.2 You may not without our prior written consent assign, transfer,
    sub-contract or otherwise deal with any of your rights and/or obligations
    under these terms and conditions.
  </p>

  <h4>13. Severability</h4>
  <p>
    13.1 If a provision of these terms and conditions is determined by any court
    or other competent authority to be unlawful and/or unenforceable, the other
    provisions will continue in effect.
    <br />
    13.2 If any unlawful and/or unenforceable provision of these terms and
    conditions would be lawful or enforceable if part of it were deleted, that
    part will be deemed to be deleted, and the rest of the provision will
    continue in effect.
  </p>

  <h4>14. Third party rights</h4>
  <p>
    14.1 These terms and conditions are for our benefit and your benefit, and
    these terms and conditions are not intended to benefit or be enforceable by
    any third party.
    <br />
    14.2 The exercise of the parties' rights under these terms and conditions is
    not subject to the consent of any third party.
  </p>

  <h4>15. Entire agreement</h4>
  <p>
    15.1 Subject to Section 9.1, these terms and conditions, together with our
    privacy policy, shall constitute the entire agreement between you and us in
    relation to your use of our website and shall supersede all previous
    agreements between you and us in relation to your use of our website.
  </p>

  <h4>16. Law and jurisdiction</h4>
  <p>
    16.1 These terms and conditions shall be governed by and construed in
    accordance with the law of England and Wales.
    <br />
    16.2 Any disputes relating to these terms and conditions shall be subject to
    the exclusive jurisdiction of the courts of England and Wales.
  </p>

  <h4>17. Statutory and regulatory disclosures</h4>
  <p>
    17.1 Mark Notton is registered as a Nutritional Advisor with the
    Naturopathic Nutrition Association.
    <br />
    17.2 Mark Notton is subject to the Naturopathic Nutrition Associations
    Professional Code of Conduct.
  </p>

  <h4>18. Our details</h4>
  <p>
    18.1 This website is owned and operated by Progress Health and Wellness Ltd
    trading as “Revivedna.com”.
    <br />
    18.2 We are registered in England and Wales under registration number
    13361824 and our registered office is Suite 93, 89 Commercial Road,
    Bournemouth, Dorset, England, BH2 5RR
    <br />
    18.3 You can contact us by using our website contact form, or by email
    info&#64;revivedna.com
  </p>
</app-content-card>
<app-get-started-section></app-get-started-section>
