import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ButtonDirective } from '../../../directives/button.directive';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-info-card',
  standalone: true,
  imports: [CommonModule, ButtonDirective, RouterModule],
  templateUrl: './info-card.component.html',
  styleUrl: './info-card.component.scss',
})
export class InfoCardComponent {
  @Input() imageSrc!: string;
  @Input() title!: string;
  @Input() content!: string;
  @Input() buttonRouterLink?: string[];

  get backgroundImage() {
    return `url(${this.imageSrc})`;
  }
}
