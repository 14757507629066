<h2
  class="title-text"
  appTitle
  accent="help"
  accentColor="#3e8364"
  class="text-center"
>
  What we can help you with
</h2>
<div class="cards">
  <div class="card">
    <div
      class="header-img"
      [ngStyle]="{ backgroundImage: 'url(/assets/img/how-we-can-help-2.png)' }"
    >
      <div class="badge">
        <div class="background">
          <span>1</span>
        </div>
      </div>
    </div>
    <h3 appTitle accent="unique" class="text-center">
      Health optimisation based on your unique DNA
    </h3>
    <button appButton color="light-green" [routerLink]="['/optimise']">
      Find out more
    </button>
  </div>
  <div class="card">
    <div
      class="header-img"
      [ngStyle]="{ backgroundImage: 'url(/assets/img/how-we-can-help-1.png)' }"
    >
      <div class="badge">
        <div class="background">
          <span>2</span>
        </div>
      </div>
    </div>
    <h3 appTitle accent="pain" class="text-center">
      Nutritional support for pain management
    </h3>
    <button appButton color="light-green" [routerLink]="['/chronic-pain']">
      Find out more
    </button>
  </div>

</div>
