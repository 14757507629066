<ng-container *ngIf="data$ | async as data">
  <app-hero-text-only style="z-index: -1">
    <h1
      header
      class="title color-white"
      appTitle
      [accent]="data?.titleAccent"
      accentColor="white"
    >
      {{ data.title }}
    </h1>
    <p class="color-white">
      {{ data.heroContent }}
    </p>
  </app-hero-text-only>
  <app-info-cards-section [cards]="infoCards$ | async">
    <h2 header class="text-center" appTitle accent="help" accentColor="#3e8364">
      Where we can help
    </h2>
  </app-info-cards-section>
  <app-cards-grid></app-cards-grid>
  <app-image-and-quotes
    imageSrc="/assets/img/food-1.png"
  ></app-image-and-quotes>
  <app-customer-feedback></app-customer-feedback>
  <app-sign-up-section
    [content]="data.signUp?.content"
    [options]="data.signUp?.options"
    [imgSrc]="data.signUp?.imgSrc"
  ></app-sign-up-section>
  <app-get-started-section></app-get-started-section>
</ng-container>
