<h3>{{ feedback.title }}</h3>
<p>
  {{ feedback.content }}
</p>
<div class="customer-details">
  <div
    class="head-shot"
    [ngStyle]="{ backgroundImage: 'url(' + feedback.headShotSrc + ')' }"
  ></div>
  <div class="customer-text">
    <div class="name">{{ feedback.customerName }}</div>
    <div class="subtext">{{ feedback.customerSubtext }}</div>
  </div>
</div>
