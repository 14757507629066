import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ButtonDirective } from '../../directives/button.directive';
import { TitleDirective } from '../../directives/title.directive';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-cards-section',
  standalone: true,
  imports: [CommonModule, ButtonDirective, TitleDirective, RouterModule],
  templateUrl: './cards-section.component.html',
  styleUrl: './cards-section.component.scss',
})
export class CardsSectionComponent {}
