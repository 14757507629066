import { Component } from '@angular/core';
import { ImageOnlySectionComponent } from '../../components/image-only-section/image-only-section.component';
import { CardsGridComponent } from '../../components/cards-grid/cards-grid.component';
import { GetStartedSectionComponent } from '../../components/get-started-section/get-started-section.component';
import { HeroTextOnlyComponent } from '../../components/hero-text-only/hero-text-only.component';
import { InfoCardsSectionComponent } from '../../components/info-cards-section/info-cards-section.component';
import { ActivatedRoute } from '@angular/router';
import { Observable, map } from 'rxjs';
import { CommonModule } from '@angular/common';
import { TitleDirective } from '../../directives/title.directive';
import { ImageAndQuotesComponent } from '../../components/image-and-quotes/image-and-quotes.component';
import { CustomerFeedbackComponent } from '../../components/customer-feedback/customer-feedback.component';
import { SignUpSectionComponent } from '../../components/sign-up-section/sign-up-section.component';

@Component({
  selector: 'app-area-overview',
  standalone: true,
  imports: [
    CardsGridComponent,
    ImageOnlySectionComponent,
    GetStartedSectionComponent,
    HeroTextOnlyComponent,
    InfoCardsSectionComponent,
    CommonModule,
    TitleDirective,
    ImageAndQuotesComponent,
    CustomerFeedbackComponent,
    SignUpSectionComponent
  ],
  templateUrl: './area-overview.component.html',
  styleUrl: './area-overview.component.scss',
})
export class AreaOverviewComponent {

  public infoCards$: Observable<any[]> = this.activeRoute.data.pipe(
    map(({ infoCards }) => infoCards || [] as any[])
  );

  public data$: Observable<any> = this.activeRoute.data;

  constructor(private activeRoute: ActivatedRoute) {}
}
