import { Component } from '@angular/core';
import { TextCardComponent } from './text-card/text-card.component';
import { ImageCardComponent } from './image-card/image-card.component';
import { TitleDirective } from '../../directives/title.directive';
import { CommonModule } from '@angular/common';
import { ButtonDirective } from '../../directives/button.directive';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-cards-grid',
  standalone: true,
  imports: [
    TextCardComponent,
    ImageCardComponent,
    TitleDirective,
    CommonModule,
    ButtonDirective,
    RouterLink,
  ],
  templateUrl: './cards-grid.component.html',
  styleUrl: './cards-grid.component.scss',
})
export class CardsGridComponent {
  public cards = [
    {
      type: 'text',
      title:
        'Reset your immune system with our 8-week immunity reset programme',
      titleAccent: 'Reset',
      icon: '/assets/icons/how-we-do-it/how-we-do-it-1.png',
      content:
        'We believe a strong immune system is the key foundation on which to build a healthy body and mind. Over the past 5 years we have developed an evidenced based programme to help support your immune system to help you on your path to improved health.',
    },
    {
      type: 'image',
      backgroundImage: '/assets/img/image-card-1.png',
      imageSrc: '/assets/img/help-card/1.png',
    },
    {
      type: 'text',
      title:
        'Understand how your body functions at a cellular level with advanced genetic insights',
      titleAccent: 'advanced',
      icon: '/assets/icons/how-we-do-it/how-we-do-it-2.png',

      content:
        'We use advanced AI technology to analyse 300 million points on your genome to help decode your individuality. We then use these insights to help deliver nutritional recommendations to help support your genes and allow your body to function more efficiently.',
    },
    {
      type: 'image',
      backgroundImage: '/assets/img/image-card-2.jpg',
      imageSrc: '/assets/img/help-card/2.png',
    },
    {
      type: 'text',
      title: 'Precision nutrition designed to support your unique DNA',
      titleAccent: 'Precision nutrition',
      icon: '/assets/icons/how-we-do-it/how-we-do-it-3.png',

      content:
        'Our meal recommendation engine takes the hassle out of meal planning. Our recipe database is optimised to each individual to prioritise the key nutrients you need to support your genes.  With more than 100 recipes included and with more being added every month, we make healthy eating simple.',
    },
    {
      type: 'image',
      backgroundImage: '/assets/img/image-card-3.png',
      imageSrc: '/assets/img/help-card/3.png',
    },
  ];
}
