import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

export interface CustomerFeedback {
  title: string;
  content: string;
  headShotSrc: string;
  customerName: string;
  customerSubtext: string;
}

@Component({
  selector: 'app-feedback-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './feedback-card.component.html',
  styleUrl: './feedback-card.component.scss',
})
export class FeedbackCardComponent {
  @Input() feedback!: CustomerFeedback;
}
