export const nutritionData = {
  title: 'Support my nutrition',
  titleAccent: 'nutrition',
  heroContent:
    'Revive leverages cutting-edge DNA analysis to deliver personalised insights tailored to individual genetic makeup, fundamentally transforming the way users approach nutrition. By analysing genetic markers related to metabolism, nutrient absorption, and food sensitivities, Revive provides users with actionable recommendations to optimize their diet and overall health.',
  areaOverview: {
    title: 'Overview',
    contentSections: [
      'Through comprehensive DNA analysis, Revive identifies genetic predispositions that influence how the body processes nutrients, such as carbohydrates, fats, and proteins. This allows users to understand their unique nutritional requirements and make informed decisions about their diet.',
      'Furthermore, Revive helps users discover potential deficiencies in key vitamins and minerals based on their genetic profile. By addressing these deficiencies through targeted dietary adjustments, users can optimise their nutrient intake and support overall well-being.',
      `Additionally, Revive provides insights into genetic factors that impact weight management, helping users understand their body's response to different dietary approaches, whether it's low-carb, low-fat, or balanced diets.`,
      `In summary, Revive empowers users to make informed decisions about their nutrition based on their unique genetic makeup, with the goal of delivering improved health outcomes and a more personalized approach to wellness.`
    ],
  },
  signUp: {
    content:
      'We send regular free tips and the latest scientific advice on how to help manage your ongoing symptoms.',
    options: [
      'Nutrition',
      'Mental health',
      'Sleep',
      'Immunity',
      'Gut health',
      'Energy',
    ],
    imgSrc: '/assets/img/how-we-can-help-2.png',
  },
};
