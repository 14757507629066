import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-image-card',
  standalone: true,
  imports: [],
  templateUrl: './image-card.component.html',
  styleUrl: './image-card.component.scss',
})
export class ImageCardComponent {
  @Input() backgroundImage!: string;
  @Input() maskOpacity?: string;

  @HostBinding('style') get styles() {
    return {
      backgroundImage: `url(${this.backgroundImage})`,
    };
  }
}
