<section>
  <h2 appTitle accent="anything" accentColor="#3e8364" class="text-center">
    Ask us anything
  </h2>
  <p>Have more questions? Find all the answers on our FAQs page</p>
  <button appButton color="light-green" [routerLink]="['/faqs']">
    View our FAQs page
  </button>
</section>

<div class="cards">
  <app-expansion-card
    *ngFor="let question of questions"
    [title]="question.question"
    [htmlContent]="question.answer"
  ></app-expansion-card>
</div>
<button
  class="mobile-button"
  appButton
  color="light-green"
  [routerLink]="['/faqs']"
>
  View our FAQs page
</button>
