import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-image-only-section',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './image-only-section.component.html',
  styleUrl: './image-only-section.component.scss',
})
export class ImageOnlySectionComponent {
  @Input() imageSrc!: string;
}
