import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { interval, map, shareReplay, startWith } from 'rxjs';
import { TitleDirective } from '../../directives/title.directive';

@Component({
  selector: 'app-image-and-quotes',
  standalone: true,
  imports: [CommonModule, TitleDirective],
  templateUrl: './image-and-quotes.component.html',
  styleUrl: './image-and-quotes.component.scss',
})
export class ImageAndQuotesComponent {
  @Input() imageSrc!: string;
  @Input() quotes: { content: string; accent?: string }[] = [
    {
      content: '50 million Americans are living in chronic pain',
      accent: '50 million',
    },
    {
      content: '14 million adults in the UK live in chronic pain',
      accent: '14 million',
    },
    {
      content: '72% of Americans get confused by nutrition labels."',
      accent: '72%',
    },
    {
      content:
        '62% of Britons have made a change to their diet to get healthier over the past year',
      accent: '62%',
    },
  ];

  public activeIndex$ = interval(5000).pipe(
    map((i) => (i + 1) % 4),
    shareReplay(),
    startWith(0)
  );

  public activeQuote$ = this.activeIndex$.pipe(map((i) => this.quotes[i]));

  @HostBinding('style') get styles() {
    return {
      backgroundImage: `url(${this.imageSrc})`,
    };
  }

  constructor(private cd: ChangeDetectorRef) {}
}
