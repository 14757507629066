import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SiteMapService {
  public siteMap = {
    sections: [
      {
        title: 'Optimise my health',
        links: [
          {
            routerLink: ['/optimise'],
            title: 'Overview',
          },
          {
            routerLink: ['/optimise', 'nutrition'],
            title: 'Nutrition',
          },
          {
            routerLink: ['/optimise', 'mental-health'],
            title: 'Mental health',
          },
          {
            routerLink: ['/optimise', 'improve-my-sleep'],
            title: 'Sleep',
          },
          {
            routerLink: ['/optimise', 'improve-my-immunity'],
            title: 'Immunity',
          },
          {
            routerLink: ['/optimise', 'gut-health'],
            title: 'Gut health',
          },
          {
            routerLink: ['/optimise', 'increase-my-energy'],
            title: 'Energy',
          },
        ],
      },
      {
        title: 'Pain management',
        links: [
          {
            routerLink: ['/chronic-pain'],
            title: 'Overview',
          },
          // {
          //   routerLink: ['/chronic-pain', 'joint-pain'],
          //   title: 'Joint pain',
          // },
          // {
          //   routerLink: ['/chronic-pain', 'migraines'],
          //   title: 'Migraines',
          // },
          // {
          //   routerLink: ['/chronic-pain', 'back-pain'],
          //   title: 'Back pain',
          // },
          // {
          //   routerLink: ['/chronic-pain', 'intestinal-pain'],
          //   title: 'Intestinal pain',
          // },
        ],
      },
    ],
    company: {
      title: 'Company',
      links: [
        {
          routerLink: ['/how-it-works'],
          title: 'How it works',
        },
        {
          routerLink: ['/about-us'],
          title: 'About us',
        },
        {
          routerLink: ['/faqs'],
          title: 'FAQs',
        },
        {
          routerLink: ['/contact'],
          title: 'Contact',
        },
      ],
    },
  };

  constructor() {}
}
