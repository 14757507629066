import { Component } from '@angular/core';
import { ExpansionCardComponent } from './expansion-card/expansion-card.component';
import { TitleDirective } from '../../directives/title.directive';
import { ButtonDirective } from '../../directives/button.directive';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-expansion-cards-section',
  standalone: true,
  imports: [
    ExpansionCardComponent,
    TitleDirective,
    ButtonDirective,
    RouterLink,
    CommonModule
  ],
  templateUrl: './expansion-cards-section.component.html',
  styleUrl: './expansion-cards-section.component.scss',
})
export class ExpansionCardsSectionComponent {
  public questions = [
    {
      question: 'What are the prices of your plans?',
      answer:
        'Our goal is to make our service available to everyone for as low a price as possible (and eventually hopefully even at no net cost per month). Once your data is sequenced and prepared and your initial subscription is finished (prices detailed on our website) we have low monthly payment plans to continue to benefit from additional Genetic reports, new menus and genetic insights. You can even buy a 6 month extension for a low cost to continue your health journey beyond your initial subscription.',
    },
    {
      question:
        'I am a little wary of the DNA test, what happens if I find out I have a big health issue?',
      answer:
        "This is a common question which many of our clients have. Your DNA is only thought to be responsible for 20% of your destiny, the more important things are what you do on a day-to-day basis and how you live your life. By understanding how you are coded it empowers you with the knowledge to know where you should focus. We are only interested in the areas which you can influence to help optimise your health, we don't scan for rare genetic diseases or anything like that.",
    },
    {
      question:
        'Is my data secure? Can I get my DNA file to analyse it myself?',
      answer:
        'We never sell your DNA data and never will, it is not part of our business model . We are only interested in analysing genetic code to provide insights that help people optimise their health. Your genetic data is yours and you can download your file through our app so you can analyse it yourself at any time. We are GDPR compliant so you can be safe in the knowledge that your data is secure. More information about how we handle your data can be provided by emailing <a href="mailto:support@revivedna.com">support@revivedna.com</a>.',
    },
    {
      question:
        'Aside from the package itself is there anything else I am committing to like additional blood tests and other costs?',
      answer:
        'Our app includes all the tools, support and guidance that you need. You will need to buy the foods we recommend and there are several optional prebiotic supplements that we recommend to improve your gut integrity. There are also additional services that we can offer which we will let you know about after you start, but these are completely at your own discretion.',
    },
    {
      question: 'Can I sign up with a friend or partner and get a discount?',
      answer:
        'Yes you can! We can save shipping costs by sending two or more packs in one shipment and can pass these cost savings straight on to you. You can also refer friends and get credits on your account, your friend can also then get a discount by being referred by you. email <a href="mailto:support@revivedna.com">support@revivedna.com</a> for more information.',
    },
    {
      question:
        "I can't afford to pay for the package in one go, is it possible to pay in installments?",
      answer:
        'Yes we can arrange this for you too. It works out a little more expensive to do it this way but there are options to help spread the cost in monthly payments. email <a href="mailto:support@revivedna.com">support@revivedna.com</a> for more information.',
    },
  ];
}
