import { Component } from '@angular/core';
import { TitleDirective } from '../../directives/title.directive';
import { CommonModule } from '@angular/common';
import { PricingCardComponent } from './pricing-card/pricing-card.component';

@Component({
  selector: 'app-pricing-section',
  standalone: true,
  imports: [TitleDirective, CommonModule, PricingCardComponent],
  templateUrl: './pricing-section.component.html',
  styleUrl: './pricing-section.component.scss'
})
export class PricingSectionComponent {

}
