export const mentalHealthData = {
  title: 'Support my mental health',
  titleAccent: 'mental health',
  heroContent: `Revive aims to provide mental health support by integrating DNA analysis to offer personalised insights. Through this innovative approach, Revive tailors nutritional recommendations specifically to an individual's genetic makeup, addressing unique needs and potential deficiencies. By understanding genetic predispositions related to mental health, such as neurotransmitter function and nutrient metabolism, we provide targeted advice on dietary choices that can positively impact mood, cognition, and overall mental well-being.`,
  areaOverview: {
    title: 'Overview',
    contentSections: [
      'Users receive customized meal plans designed to help with brain function and mood regulation based on their genetic profile. This approach not only enhances physical health but also supports mental resilience and emotional stability. Additionally, Revive offers educational resources and tracking tools to empower users to make informed decisions about their nutrition and monitor their progress over time.',
      'By leveraging cutting-edge technology and scientific research, we aim to empower individuals to take proactive steps towards better mental health through personalized nutrition strategies. This holistic approach acknowledges the intricate connection between diet and mental well-being, offering users a comprehensive solution to support their mental health journey.',
    ],
  },
  signUp: {
    content:
      'We send regular free tips and the latest scientific advice on how to help manage your ongoing symptoms.',
    options: [
      'Nutrition',
      'Mental health',
      'Sleep',
      'Immunity',
      'Gut health',
      'Energy',
    ],
    imgSrc: '/assets/img/how-we-can-help-2.png',
  },
};
