import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-content-card',
  standalone: true,
  imports: [],
  templateUrl: './content-card.component.html',
  styleUrl: './content-card.component.scss',
})
export class ContentCardComponent {
  @Input() maskOpacity = '0';
  @Input() backgroundImage?: string;

  @HostBinding('style') get styles() {
    return {
      backgroundImage: this.backgroundImage ? `url(${this.backgroundImage})` : null,
    };
  }
}
