import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-image-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './image-card.component.html',
  styleUrl: './image-card.component.scss',
  host: {
    '[styles.backgroundImage]': 'imageSrc',
  },
})
export class ImageCardComponent {
  @Input() backgroundImage!: string;
  @Input() imageSrc!: string;
}
