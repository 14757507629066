<section class="title">
  <ng-content select="[header]"></ng-content>
</section>
<section class="cards">
  <app-info-card
    *ngFor="let card of cards"
    [imageSrc]="card.imageSrc"
    [title]="card.title"
    [content]="card.content"
    [buttonRouterLink]="card.routerLink"
  ></app-info-card>
</section>
