import { Component } from '@angular/core';
import { HeroTextOnlyComponent } from '../../components/hero-text-only/hero-text-only.component';
import { ContentCardComponent } from '../../components/content-card/content-card.component';
import { TitleDirective } from '../../directives/title.directive';
import { ImageCardComponent } from '../../components/image-card/image-card.component';
import { QuoteComponent } from '../../components/quote/quote.component';
import { InfoCardComponent } from '../../components/info-card/info-card.component';
import { GetStartedSectionComponent } from '../../components/get-started-section/get-started-section.component';

@Component({
  selector: 'app-how-it-works',
  standalone: true,
  imports: [
    HeroTextOnlyComponent,
    ContentCardComponent,
    TitleDirective,
    ImageCardComponent,
    QuoteComponent,
    InfoCardComponent,
    GetStartedSectionComponent,
  ],
  templateUrl: './how-it-works.component.html',
  styleUrl: './how-it-works.component.scss',
})
export class HowItWorksComponent {
  scrollTo(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}
