import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { HeroTextOnlyComponent } from '../../components/hero-text-only/hero-text-only.component';
import { TitleDirective } from '../../directives/title.directive';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { TextLongFormComponent } from '../../components/text-long-form/text-long-form.component';
import { ImageAndQuotesComponent } from '../../components/image-and-quotes/image-and-quotes.component';
import { PhaseCardsSectionComponent } from '../../components/phase-cards-section/phase-cards-section.component';
import { CardsGridTwoComponent } from '../../components/cards-grid-two/cards-grid-two.component';
import { CustomerFeedbackComponent } from '../../components/customer-feedback/customer-feedback.component';
import { PricingSectionComponent } from '../../components/pricing-section/pricing-section.component';
import { ExpansionCardsSectionComponent } from '../../components/expansion-cards-section/expansion-cards-section.component';
import { SignUpSectionComponent } from '../../components/sign-up-section/sign-up-section.component';
import { GetStartedSectionComponent } from '../../components/get-started-section/get-started-section.component';
import { ImageAndTextCardSectionComponent } from '../../components/image-and-text-card-section/image-and-text-card-section.component';

@Component({
  selector: 'app-specific-area',
  standalone: true,
  imports: [
    CommonModule,
    HeroTextOnlyComponent,
    TitleDirective,
    TextLongFormComponent,
    ImageAndQuotesComponent,
    PhaseCardsSectionComponent,
    CardsGridTwoComponent,
    CustomerFeedbackComponent,
    PricingSectionComponent,
    ExpansionCardsSectionComponent,
    SignUpSectionComponent,
    GetStartedSectionComponent,
    ImageAndTextCardSectionComponent,
  ],
  templateUrl: './specific-area.component.html',
  styleUrl: './specific-area.component.scss',
})
export class SpecificAreaComponent {
  public data$: Observable<any> = this.activeRoute.data;

  constructor(private activeRoute: ActivatedRoute) {}
}
