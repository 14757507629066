import { Component } from '@angular/core';
import { HeroTextOnlyComponent } from '../../components/hero-text-only/hero-text-only.component';
import { ContentCardComponent } from '../../components/content-card/content-card.component';
import { TitleDirective } from '../../directives/title.directive';
import { ImageAndQuotesComponent } from '../../components/image-and-quotes/image-and-quotes.component';
import { DonationComponent } from '../../components/donation/donation.component';
import { GetStartedSectionComponent } from '../../components/get-started-section/get-started-section.component';
import { ImageAndTextCardSectionComponent } from '../../components/image-and-text-card-section/image-and-text-card-section.component';
import { ButtonDirective } from '../../directives/button.directive';

@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [
    HeroTextOnlyComponent,
    ContentCardComponent,
    TitleDirective,
    ImageAndQuotesComponent,
    ImageAndTextCardSectionComponent,
    DonationComponent,
    GetStartedSectionComponent,
    ButtonDirective,
  ],
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss',
})
export class AboutUsComponent {}
