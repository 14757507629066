import { Component } from '@angular/core';
import { HeroTextOnlyComponent } from '../../components/hero-text-only/hero-text-only.component';
import { TitleDirective } from '../../directives/title.directive';
import { ContentCardComponent } from '../../components/content-card/content-card.component';
import { CommonModule } from '@angular/common';
import { GetStartedSectionComponent } from '../../components/get-started-section/get-started-section.component';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ButtonDirective } from '../../directives/button.directive';

@Component({
  selector: 'app-contact-us',
  standalone: true,
  imports: [
    CommonModule,
    HeroTextOnlyComponent,
    TitleDirective,
    ContentCardComponent,
    GetStartedSectionComponent,
    FormsModule,
    ReactiveFormsModule,
    ButtonDirective
  ],
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss',
})
export class ContactUsComponent {
  public contactOptions = [
    {
      icon: 'assets/icons/contact-us/general.png',
      title: 'General Enquiries',
      link: 'info@revivedna.com',
    },
    {
      icon: 'assets/icons/contact-us/media.png',
      title: 'Media Enquiries',
      link: 'media@revivedna.com',
    },
    {
      icon: 'assets/icons/contact-us/investors.png',
      title: 'Investors',
      link: 'investor@revivedna.com',
    },
    {
      icon: 'assets/icons/contact-us/careers.png',
      title: 'Careers',
      link: 'careers@revivedna.com',
    },
  ];

  public formGroup = new FormGroup({
    name: new FormControl<string>(''),
    email: new FormControl<string>(''),
    phone: new FormControl<string>(''),
    message: new FormControl<string>(''),
  });
}
