<app-hero-text-only>
  <h1
    header
    class="color-white"
    appTitle
    accent="questions"
    accentColor="white"
  >
    Frequently asked questions
  </h1>
</app-hero-text-only>
<app-content-card
  style="background-color: white; margin: -40px 0 0; z-index: +1"
>
  <section *ngFor="let section of config" class="questions-section">
    <h2>{{ section.title }}</h2>
    <div class="questions">
      <app-expansion-card
        *ngFor="let question of section.questions"
        [title]="question.question"
        [htmlContent]="question.answer"
      ></app-expansion-card>
    </div>
  </section>
  <hr style="margin: 0" />
  <h2>Still looking for answers?</h2>
  <p>
    Email <a href="mailto:support@revivedna.com">support&#64;revivedna.com</a>
  </p>
</app-content-card>
<app-get-started-section> </app-get-started-section>
