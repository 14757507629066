<h2
  appTitle
  accentColor="#3E8364"
  [accent]="data.titleAccent"
  [dynamicContent]="data.title"
></h2>

<ng-container *ngFor="let section of data.content" [ngSwitch]="section.type">
  <h4 *ngSwitchCase="'h4'">
    {{ section.content }}
  </h4>

  <p *ngSwitchDefault>
    {{ section.content }}
  </p>
</ng-container>

<div class="close-icon">
  <img
    (click)="close()"
    src="/assets/icons/circle-close-green.png"
    class="close-icon"
  />
</div>
