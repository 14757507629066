import { Component, Inject } from '@angular/core';
import {
  Dialog,
  DIALOG_DATA,
  DialogModule,
  DialogRef,
} from '@angular/cdk/dialog';
import { TitleDirective } from '../../directives/title.directive';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-phase-detail-dialog',
  standalone: true,
  imports: [DialogModule, TitleDirective, CommonModule],
  templateUrl: './phase-detail-dialog.component.html',
  styleUrl: './phase-detail-dialog.component.scss',
})
export class PhaseDetailDialogComponent {
  constructor(
    private dialogRef: DialogRef<PhaseDetailDialogComponent>,
    @Inject(DIALOG_DATA) public data: PhaseDetailDialogData
  ) {}
  close() {
    this.dialogRef.close();
  }
  public static open(dialog: Dialog, data: PhaseDetailDialogData) {
    return dialog.open(PhaseDetailDialogComponent, {
      data,
    });
  }
}

export interface PhaseDetailDialogData {
  title: string;
  titleAccent: string;
  content: {
    type: 'h4' | 'p';
    content: string;
  }[];
}
