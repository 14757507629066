import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ButtonDirective } from '../../directives/button.directive';

@Component({
  selector: 'app-donation',
  standalone: true,
  imports: [CommonModule, ButtonDirective],
  templateUrl: './donation.component.html',
  styleUrl: './donation.component.scss',
})
export class DonationComponent {
  get backgroundSrc() {
    return `url(/assets/img/donation.png)`;
  }
}
