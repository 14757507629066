export const optimiseData = {
  title: 'Health optimisation based on your unique DNA ',
  titleAccent: 'Health optimisation',
  heroContent:
    'We are all unique and each of us have our own particular strengths and weaknesses. We unlock the secrets to your genetic code and build nutrition plans to help you optimise your health',
  infoCards: [
    {
      imageSrc: '/assets/img/info-card/nutrition.png',
      title: 'Support my nutrition',
      content:
        'Is your metabolism optimal? Maybe, but probably not. A recent study indicated that only about 1 in 8 people in the U.S. have optimal metabolic health',
      routerLink: ['nutrition'],
    },
    {
      imageSrc: '/assets/img/info-card/mental-health.jpeg',
      title: 'Support my mental health',
      content: `Past experiences, one's current environment, diet, lifestyle, and your DNA all affect how the brain functions and responds`,
      routerLink: ['mental-health'],
    },
    {
      imageSrc: '/assets/img/info-card/sleep.png',
      title: 'Support my sleep',
      content:
        'Trouble sleeping can lead to chronic tiredness, irritability, difficulty concentrating, and a general lack of energy',
      routerLink: ['improve-my-sleep'],
    },
    {
      imageSrc: '/assets/img/info-card/immunity.png',
      title: 'Support my immunity',
      content:
        'Your immune cells are a formidable force in keeping you healthy and preventing infections',
      routerLink: ['improve-my-immunity'],
    },
    {
      imageSrc: '/assets/img/info-card/gut-health.png',
      title: 'Support my gut health',
      content:
        'Your gut is an amazingly complex system of organs, finely tuned to take in and process nutrients and then get rid of the waste.',
      routerLink: ['gut-health'],
    },
    {
      imageSrc: '/assets/img/info-card/energy.png',
      title: 'Increase my energy',
      content:
        'The food we eat and how our body absorbs it has a massive impact on our energy levels',
      routerLink: ['increase-my-energy'],
    },
  ],
  signUp: {
    content:
      'We send regular free tips and the latest scientific advice on how to help manage your ongoing symptoms.',
    options: [
      'Nutrition',
      'Mental health',
      'Sleep',
      'Immunity',
      'Gut health',
      'Energy',
    ],
    imgSrc: '/assets/img/how-we-can-help-2.png',
  },
};

//
