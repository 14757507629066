import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-image-and-text-card-section',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './image-and-text-card-section.component.html',
  styleUrl: './image-and-text-card-section.component.scss',
})
export class ImageAndTextCardSectionComponent {
  @Input() imageSrc!: string;

  get backgroundImageUrl() {
    return `url(${this.imageSrc})`;
  }
}
