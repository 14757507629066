import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TitleDirective } from '../../directives/title.directive';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ButtonDirective } from '../../directives/button.directive';

@Component({
  selector: 'app-sign-up-section',
  standalone: true,
  imports: [
    CommonModule,
    TitleDirective,
    FormsModule,
    ReactiveFormsModule,
    ButtonDirective,
  ],
  templateUrl: './sign-up-section.component.html',
  styleUrl: './sign-up-section.component.scss',
})
export class SignUpSectionComponent {
  @Input() content!: string;
  @Input() options!: string[];
  @Input() imgSrc!: string;

  get imageSrc() {
    return `url(${this.imgSrc})`;
  }
  public formGroup = new FormGroup({
    email: new FormControl(),
    area: new FormControl(),
  });
}
