export const gutHealthData = {
  title: 'Support my gut health',
  titleAccent: 'gut health',
  heroContent: `Revive, aims to support improved gut health by leveraging DNA analysis for personalised insights. By examining an individual's genetic makeup, Revive identifies specific dietary requirements tailored to their unique genetic predispositions. This personalised approach ensures targeted recommendations for optimising gut health.`,
  areaOverview: {
    title: 'Overview',
    contentSections: [
      'Through comprehensive analysis, Revive pinpoints potential genetic factors influencing gut health, such as enzyme deficiencies or sensitivities to certain foods. It also delivers an 8 week immunity reset diet aimed at promoting gut microbiome balance and overall digestive wellness.',
      'Moreover, Revive empowers users with a deeper understanding of their genetic predispositions, fostering informed decision-making regarding dietary choices. By aligning nutrition with genetic profiles, users may mitigate potential digestive issues, optimise nutrient absorption, and promote a healthier gut environment.',
      'Furthermore, Revive offers ongoing support and monitoring, allowing users to track their progress. This dynamic approach to gut health management ensures continual optimisation and personalized care.',
      'In summary, Revive stands at the forefront of nutrition by harnessing DNA analysis to provide personalised insights and recommendations aimed at improving gut health. With its tailored approach and ongoing support, Revive empowers users to take control of their digestive wellness like never before.',
    ],
  },
  signUp: {
    content:
      'We send regular free tips and the latest scientific advice on how to help manage your ongoing symptoms.',
    options: [
      'Nutrition',
      'Mental health',
      'Sleep',
      'Immunity',
      'Gut health',
      'Energy',
    ],
    imgSrc: '/assets/img/how-we-can-help-2.png',
  },
};
