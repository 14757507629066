<ng-container *ngIf="data$ | async as data">
  <app-hero-text-only style="z-index: -1">
    <h1
      header
      class="color-white"
      appTitle
      [accent]="data?.titleAccent"
      accentColor="white"
    >
      {{ data.title }}
    </h1>
    <p class="color-white">
      {{ data.heroContent }}
    </p>
  </app-hero-text-only>
  <app-text-long-form [data]="data.areaOverview"></app-text-long-form>
  <app-image-and-quotes
    imageSrc="/assets/img/food-1.png"
  ></app-image-and-quotes>
  <app-phase-cards-section></app-phase-cards-section>
  <app-cards-grid-two></app-cards-grid-two>
  <app-customer-feedback></app-customer-feedback>
  <app-pricing-section></app-pricing-section>
  <app-image-and-text-card-section
    imageSrc="/assets/img/image-card-2.jpg"
    style="margin: -40px 0; z-index: -1"
  >
    <h2
      appTitle
      accent="science"
      accentColor="#3e8364"
      style="max-width: 290px"
    >
      The science behind Revive
    </h2>
    <div class="title"></div>
    <p style="color: #1e3e3099">
      <strong style="color: #1e3e30">
        Everything we do is based on using the most up to date scientific data.
      </strong>
      <br />
      We combine cutting-edge biochemistry research with your unique genetic
      code, to give you a comprehensive explanation of the "why" behind our
      recommendations. These insights pave the way for you to conduct your own
      research to learn more about your individuality and discover additional
      areas to optimise your health.
    </p>
  </app-image-and-text-card-section>
  <app-expansion-cards-section></app-expansion-cards-section>
  <app-sign-up-section
    [content]="data.signUp?.content"
    [options]="data.signUp?.options"
    [imgSrc]="data.signUp?.imgSrc"
  ></app-sign-up-section>
  <app-get-started-section></app-get-started-section>
</ng-container>
