<!-- <img src="/assets/img/donation.png" /> -->
<div class="image-card" [ngStyle]="{ backgroundImage: backgroundSrc }"></div>
<div class="content-card">
  <h4 class="color-white">Making a difference</h4>
  <p class="color-white">
    Revive gives back to support charities that do incredible work to support
    people living with chronic illnesses. By investing in your own health, you
    contribute to these charities - we donate $10 for every subscriber to our
    platform to give back to our community.
  </p>
  <p class="color-white">
    If you can spare any money, they would be so grateful, and you can make a
    donation by clicking the link below.
  </p>
  <button appButton color="trans-white" style="margin-top: 40px;">
    <img src="/assets/img/arrow-green-circle-white.png" />
    Make a donation
  </button>
</div>
