export const chronicPainData = {
  title: 'Nutritional support for pain management',
  titleAccent: 'pain management',
  heroContent:
    'Chronic Pain and autoimmune conditions can ruin the lives of previously happy and healthy people. Our goal is to offer solutions to help support manage these conditions with precision nutrition plans. Whilst we are not yet offering this service, we are preparing 3 clinical trials for our solution with a leading University in the UK and are looking for volunteers to help provide clinical grade insights into our platform. If you suffer with one of the areas below please sign up to receive more information through the contact form below.',
  infoCards: [
    {
      imageSrc: '/assets/img/info-card/joint-pain.png',
      title: 'Joint pain',
      content:
        'Where our business started, nutritional support to help manage joint pain',
      // routerLink: ['joint-pain'],
    },
    {
      imageSrc: '/assets/img/info-card/migraines.png',
      title: 'Migraines',
      content: `Analysis of key genes related to migraines and nutritional support to help your body`,
      // routerLink: ['migraines'],
    },
    {
      imageSrc: '/assets/img/info-card/back-pain.png',
      title: 'Back pain',
      content: 'Nutritional support to manage back pain conditions',
      // routerLink: ['back-pain'],
    },
    {
      imageSrc: '/assets/img/info-card/intestinal-pain.png',
      title: 'Intestinal pain',
      content: 'A focus on gut health to help support immune function',
      // routerLink: ['intestinal-pain'],
    },
  ],
  signUp: {
    content:
      'We are preparing 3 clinical trials. If you are interested to learn more, please provide your details below',
    options: ['Joint pain', 'Migraines', 'Back pain', 'Intestinal pain'],
    imgSrc: '/assets/img/how-we-can-help-1.png',
  },
};
