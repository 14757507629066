<div class="mask"></div>
<h3
  *ngIf="activeQuote$ | async as quote"
  appTitle
  [accent]="quote.accent"
  [dynamicContent]="quote.content"
  accentColor="white"
></h3>
<div class="index-indicators">
  <div
    *ngFor="let quote of quotes; let i = index"
    class="indicator"
    [ngClass]="{ active: i === (activeIndex$ | async) }"
  ></div>
</div>
