import { Component } from '@angular/core';
import { BenefitCardComponent } from './benefit-card/benefit-card.component';
import { TitleDirective } from '../../directives/title.directive';

@Component({
  selector: 'app-cards-grid-two',
  standalone: true,
  imports: [BenefitCardComponent, TitleDirective],
  templateUrl: './cards-grid-two.component.html',
  styleUrl: './cards-grid-two.component.scss',
})
export class CardsGridTwoComponent {}
