<section>
  <h2 appTitle accent="Revive" accentColor="#3e8364" class="text-center">
    What do others say about Revive?
  </h2>
</section>
<div class="hero-feedback">
  <div class="image">
    <div class="customer-details">
      <div class="name">Joel Greene</div>
      <div class="subtext">Author of “The Immunity Code” and “The Way”</div>
    </div>
  </div>
  <div class="hero-quote">
    <img class="open-quote" src="/assets/icons/open-quote.svg" />

    <div class="title color-white">A game changer</div>

    <p class="color-white">
      “Revive are innovating a marriage between applied technology and cutting
      edge immune centric protocols. They are at the forefront of understanding
      how things really work when it comes to nutrition.”
    </p>
  </div>
</div>
<div class="cards">
  <app-feedback-card
    *ngFor="let feedback of feedbackItems"
    [feedback]="feedback"
  ></app-feedback-card>
</div>
