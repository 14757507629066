<small *ngIf="topText">{{ topText }}</small>
<img class="card-top-border" src="/assets/img/card-top-border.svg" />
<div class="card-container">
  <div *ngIf="topTabText" class="top-tab">{{ topTabText }}</div>
  <div
    class="card"
    [ngClass]=""
    [ngStyle]="{
      backgroundColor: cardColor,
      borderTopLeftRadius: !topTabText ? '8px' : 0,
      marginTop: !topTabText ? '35px' : '0'
    }"
  >
    <div class="heading">
      <div class="card-title">{{ title }}</div>
      <div *ngIf="subTitle" class="card-subtitle">{{ subTitle }}</div>
    </div>

    <div class="price">
      <span class="currency">£</span>
      <span class="value">{{ price }}</span>
    </div>

    <div class="included-items">
      <div class="section-title">Includes:</div>
      @for (item of includedItems; track $index) {
      <div class="item">
        <img src="/assets/icons/check-green-transparent.svg" />
        <div>{{ item }}</div>
      </div>
      }
    </div>

    <button
      *ngIf="buttonLink"
      appButton
      (click)="navTo(buttonLink)"
      [color]="buttonColor"
    >
      <img
        [src]="
          buttonColor === 'outline-orange'
            ? '/assets/img/arrow-right-circle-orange.svg'
            : '/assets/img/arrow-right-circle-white.svg'
        "
      />
      {{ buttonText }}
    </button>
  </div>
</div>
<div
  *ngIf="bottomCardContent"
  appTitle
  class="bottom-card"
  [dynamicContent]="bottomCardContent"
  [accent]="bottomCardAccent"
  accentColor="#3E8364"
></div>
