<div class="navbar" [ngClass]="{ mobileOpen: mobileMenuOpen }">
  <img
    src="/assets/img/logo-header.svg"
    class="header-img"
    [routerLink]="['/']"
    (click)="mobileMenuOpen = false"
  />

  <img
    class="menu-button"
    [src]="
      mobileMenuOpen
        ? '/assets/icons/close-green.svg'
        : '/assets/icons/menu-green.svg'
    "
    (click)="mobileMenuOpen = !mobileMenuOpen"
  />
</div>

<div class="mobile-menu" *ngIf="mobileMenuOpen">
  <div class="mobile-nav-items">
    <a class="active" (click)="whereWeCanHelpOpen = !whereWeCanHelpOpen">
      Where we can help
      <img
        src="/assets/icons/chevron.svg"
        [ngClass]="{ open: whereWeCanHelpOpen }"
      />
    </a>
    <div class="expansion-section" *ngIf="whereWeCanHelpOpen">
      <div
        *ngFor="let section of siteMap.siteMap.sections; let last = last"
        class="nav-section"
      >
        <div class="header">{{ section.title }}</div>
        <a
          *ngFor="let link of section.links"
          [routerLink]="link.routerLink"
          (click)="mobileMenuOpen = false"
          >{{ link.title }}</a
        >
        <hr *ngIf="!last" style="margin-top: 16px" />
      </div>
    </div>
    <a
      *ngFor="let link of siteMap.siteMap.company.links"
      [routerLink]="link.routerLink"
      (click)="mobileMenuOpen = false"
      >{{ link.title }}</a
    >
  </div>

  <hr />

  <button
    appButton
    (click)="navTo('https://join.revivedna.com')"
    (click)="mobileMenuOpen = false"
    style="margin-bottom: 40px"
  >
    <img src="/assets/img/arrow-right-circle-white.svg" />
    Get Started
  </button>
</div>
