<app-hero-text-only>
  <h1 header class="color-white" appTitle accent="us" accentColor="white">
    About us
  </h1>
  <p class="color-white">
    We setup Revive to make healthy eating simple, and are driven by a passion
    to help people. Most of us spend years trying to find out the right diet
    that we need to thrive. By using revive you get your own genetic source code
    and remove the guess work to what your body needs. By tailoring meal plans
    to your unique nutritional needs we take the guess work out of healthy
    eating.
  </p>
</app-hero-text-only>
<app-content-card
  maskOpacity=".7"
  backgroundImage="assets/img/about-us/about-us-1.png"
  style="
    z-index: +1;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  "
>
  <div class="overview-wrapper">
    <h1 appTitle accent="Revive" accentColor="white" class="color-white">
      Why we started Revive
    </h1>
    <div class="overview-content">
      <p class="color-white">
        Katie was a fit and healthy mum of 2 in her late 30s, but a complicated
        gallbladder operation led to an autoimmune cascade causing her to lose
        the use of both of her hands. Steroid injections and continuous use of
        anti - inflammatories made no difference, her doctor was all out of
        ideas.
      </p>
      <hr />
      <p class="color-white">
        Lockdown hit and her husband spent months studying emerging biochemical
        and nutritional research in an effort to cure her daily pain. He built a
        treatment plan based on supporting her immune system based on her
        genetics.
        <br />
        3 weeks later she was out of pain and on the path to a full recovery.
      </p>
      <hr />
      <p class="color-white">
        The food we eat has an incredible impact on how we feel, our energy
        levels, our pain and stress levels and how we sleep. Through 5 years of
        research based on the work of pioneers in the nutrition field we set out
        on a mission to bring this science to as many people as possible by
        making it as simple and affordable as possible.
      </p>
    </div>
  </div>
</app-content-card>
<app-content-card style="z-index: +1">
  <div class="overview-wrapper">
    <h1 appTitle accent="manifesto">Our manifesto</h1>
    <div class="overview-content">
      <p>
        We are establishing a mission-driven community, where health
        optimization meets personalized education. Our manifesto is rooted in a
        commitment to empower individuals globally with knowledge and insights
        that transcend generic health advice. We do this by illuminating the
        intricate link between the foods we eat and our unique DNA, recognizing
        that our bodies are wired uniquely, influencing how we thrive.
      </p>
      <hr />
      <p>
        At the core of our philosophy is the belief that many health issues can
        be unravelled by understanding the blueprint written in your genes.
        Through cutting-edge analysis, we delve into your DNA, unlocking a
        personalized education about your body. We don’t profess to have all the
        answers, but we strive to be a beacon of knowledge, demystifying the
        complexities of health through accessible and informed content.
      </p>
      <hr />
      <p>
        Our manifesto isn't just about words; it's a pledge to impact lives. Our
        goal is ambitious yet straightforward: to help one million individuals
        on their health journey, offering personalized insights for less than $1
        per day and ultimately hoping to be able to deliver our service
        completely free of charge. Join us in this transformative mission, where
        education is the catalyst for optimal health, and together, let's
        redefine the way we approach well-being.
      </p>
    </div>
  </div>
</app-content-card>
<app-image-and-quotes imageSrc="/assets/img/food-1.png"></app-image-and-quotes>
<app-content-card style="background-color: white">
  <div class="timeline-wrapper">
    <h2 appTitle accent="timeline" accentColor="#3e8364" style="color: #1e3e30">
      Our timeline
    </h2>
    <div class="overview-content">
      <div class="timeline-section">
        <img src="assets/icons/timeline.png" />
        <div class="timeline-section-content">
          <h3 style="color: #3e8364">Apr - Jun 20</h3>
          <p>
            Successfully reversed wife's autoimmune issues through nutrition
          </p>
        </div>
      </div>
      <hr />
      <div class="timeline-section">
        <img src="assets/icons/timeline.png" />
        <div class="timeline-section-content">
          <h3 style="color: #3e8364">Sept 20 - Mar 21</h3>
          <p>Developed personalised solution working with clients in the UK</p>
        </div>
      </div>
      <hr />

      <div class="timeline-section">
        <img src="assets/icons/timeline.png" />
        <div class="timeline-section-content">
          <h3 style="color: #3e8364">Apr 21 - Sep 21</h3>
          <p>
            Setup relationships with world leading nutrition experts and
            geneticists
          </p>
        </div>
      </div>
      <hr />

      <div class="timeline-section">
        <img src="assets/icons/timeline.png" />
        <div class="timeline-section-content">
          <h3 style="color: #3e8364">Sep 21 - Jan 22</h3>
          <p>
            Studied under global immunity Authority Joel Greene to develop
            immune centric approach to establishing gut integrity
          </p>
        </div>
      </div>
      <hr />

      <div class="timeline-section">
        <img src="assets/icons/timeline.png" />
        <div class="timeline-section-content">
          <h3 style="color: #3e8364">Oct 22</h3>
          <p>Launched our business</p>
        </div>
      </div>
      <hr />

      <div class="timeline-section">
        <img src="assets/icons/timeline.png" />
        <div class="timeline-section-content">
          <h3 style="color: #3e8364">Q1 23 - Q1 24</h3>
          <p>
            Raised £250k of pre-seed investment and Developed our App with world
            leading development team
          </p>
        </div>
      </div>
      <hr />

      <div class="timeline-section">
        <img src="assets/icons/timeline.png" />
        <div class="timeline-section-content">
          <h3 style="color: #3e8364">April 24</h3>
          <p>Launch Revive Precision nutrition app</p>
        </div>
      </div>
    </div>
  </div>
</app-content-card>
<app-content-card style="z-index: +1" class="mission-solution">
  <section>
    <h4>Our Mission</h4>
    <h2 appTitle accent="improve their health" accentColor="#3E8364">
      To help 1 million people improve their health for less than $1 per day
    </h2>
  </section>
  <hr style="margin: 0" />
  <section>
    <h4>Our Solution</h4>
    <h2 appTitle accent="empower" accentColor="#3E8364">
      We use genetic insights to empower people to optimise their health
    </h2>
  </section>
</app-content-card>
<app-image-and-text-card-section
  imageSrc="/assets/img/investor.jpeg"
  style="margin-top: -40px"
>
  <h3 appTitle accent="investor" accentColor="#3E8364">Become an investor</h3>
  <p>
    Our investors have been on a purpose driven mission to help others. By
    investing in revive you can make a direct impact on the health of people
    living all around the world
  </p>
  <button appButton color="outline-orange">
    <img src="/assets/img/arrow-right-circle-orange.svg" />
    Become an investor
  </button>
</app-image-and-text-card-section>
<app-donation></app-donation>
<app-get-started-section></app-get-started-section>
