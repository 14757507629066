import { Component } from '@angular/core';

@Component({
  selector: 'app-hero-text-only',
  standalone: true,
  imports: [],
  templateUrl: './hero-text-only.component.html',
  styleUrl: './hero-text-only.component.scss'
})
export class HeroTextOnlyComponent {

}
