export const improveMyImmunityData = {
  title: 'Support my immunity',
  titleAccent: 'immunity',
  heroContent: `Revive aims to improve health outcomes by leveraging DNA analysis to offer personalised insights, particularly in enhancing immunity. By analysing genetic markers related to immune function, Revive tailors dietary recommendations to each individual's unique genetic makeup. Through this approach, users receive precise guidance on nutrients crucial for bolstering immune responses, such as vitamin C, D, zinc, and antioxidants.`,
  areaOverview: {
    title: 'Overview',
    contentSections: [
      'With Revive, users gain a deeper understanding of their genetic predispositions toward immune health, allowing them to make informed dietary choices. For instance, individuals with variations impacting vitamin absorption may be advised on alternative sources or supplementation strategies. Furthermore, Revive educates users on lifestyle factors that can influence immunity, including sleep, stress management, and physical activity, thereby offering a comprehensive approach to wellness.',
      'Our user interface provides easy access to personalized meal plans, recipes, and nutritional tips tailored to individual genetic profiles. By optimizing nutrient intake based on genetic insights, users can strengthen their immune systems, potentially reducing susceptibility to infections and enhancing overall health and well-being. Revive empowers users to take proactive steps towards optimizing their immune function, ultimately fostering a healthier and more resilient lifestyle.',
    ],
  },
  signUp: {
    content:
      'We send regular free tips and the latest scientific advice on how to help manage your ongoing symptoms.',
    options: [
      'Nutrition',
      'Mental health',
      'Sleep',
      'Immunity',
      'Gut health',
      'Energy',
    ],
    imgSrc: '/assets/img/how-we-can-help-2.png',
  },
};
