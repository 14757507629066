<section>
  <h2 appTitle accent="pricing" accentColor="#3E8364" class="text-center">
    How our pricing works
  </h2>
</section>
<div class="pricing-cards">
  <app-pricing-card
    topText="Already have your DNA file?"
    topTabText="7 day free trial"
    title="6 months"
    price="129.95"
    bottomCardContent="At the end of your subscription period the price drops to £8.95 month"
    bottomCardAccent="£8.95 month"
    [includedItems]="[
      'Learn your unique genetic code',
      'Tailored nutritional recommendations',
      'Learn your superfoods',
      'Make healthy eating convenient',
      '8 Week Immune reset programme',
      'Daily goals to keep you motivated'
    ]"
  >
  </app-pricing-card>
  <app-pricing-card
    topText="Already have your DNA file?"
    topTabText="7 day free trial"
    title="12 months"
    price="169.95"
    [includedItems]="[
      'Learn your unique genetic code',
      'Tailored nutritional recommendations',
      'Learn your superfoods',
      'Make healthy eating convenient',
      '8 Week Immune reset programme',
      'Daily goals to keep you motivated'
    ]"
    bottomCardContent="At the end of your subscription period the price drops to £8.95 month"
    bottomCardAccent="£8.95 month"
  >
  </app-pricing-card>
  <app-pricing-card
    topText="Sequence my DNA"
    cardColor="white"
    title="Genetic Analysis Pack"
    subTitle="One off payment"
    price="99.95"
    buttonText="Join waitlist"
    [includedItems]="[
      'Optimised for AI - 300 million SNP\'s',
      'Simple cheek swab collection kit',
      '98% call rate for highest accuracy',
      'Results back in 6-8 weeks',
      'Includes all shipping costs'
    ]"
    buttonColor="outline-orange"
  >
  </app-pricing-card>
</div>
