<section>
  <h2 appTitle accent="change" accentColor="#3e8364">
    We set out to change lives
  </h2>
</section>
<p>Everything we do is all about you</p>
<button appButton (click)="navTo('https://join.revivedna.com')">
  <img src="/assets/img/arrow-right-circle-white.svg" />
  Get started today
</button>
<div class="background">
  <div class="mask"></div>
</div>
