import { Component } from '@angular/core';
import { ButtonDirective } from '../../directives/button.directive';
import { RouterModule } from '@angular/router';
import { TitleDirective } from '../../directives/title.directive';

@Component({
  selector: 'app-hero',
  standalone: true,
  imports: [ButtonDirective, RouterModule, TitleDirective],
  templateUrl: './hero.component.html',
  styleUrl: './hero.component.scss',
})
export class HeroComponent {
  navTo(path: string) {
    window.location.assign(path);
  }
}
