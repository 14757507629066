<div
  class="background"
  [ngStyle]="{ backgroundImage: 'url( ' + backgroundImage + ')' }"
>
<img [src]="imageSrc">
  <!-- <div
    class="content"
    [ngStyle]="{ backgroundImage: 'url( ' + backgroundImage + ')' }"
  >
    <div class="block">
    </div>
  </div> -->
</div>
