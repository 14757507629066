<div class="image" [ngStyle]="{ backgroundImage: imageSrc }"></div>

<div class="content">
  <h2 appTitle accent="newsletter" accentColor="#3e8364">
    Sign up to our newsletter
  </h2>
  <p>
    {{ content }}
  </p>
  <form [formGroup]="formGroup">
    <div class="input-container">
      <label>Email address*</label>
      <input
        formControlName="email"
        type="email"
        placeholder="Enter your email address"
      />
    </div>

    <div class="input-container">
      <label>Select an area you would like more information on</label>
      <select formControlName="area">
        <option *ngFor="let option of options">{{ option }}</option>
      </select>
    </div>

    <button appButton>
      <img src="/assets/img/arrow-right-circle-white.svg" />
      Sign up
    </button>
  </form>
</div>
