<small>{{ data.small }}</small>
<img src="/assets/img/card-top-border.svg" />
<div
  class="progress-indicator"
  [ngStyle]="{ opacity: data.indicatorOpacity }"
></div>
<div
  class="card-title"
  appTitle
  [accent]="data.titleAccent"
  accentColor="#3E8364"
  [dynamicContent]="data.title"
></div>
<p>
  {{ data.content }}
</p>
<button appButton color="white" (click)="openDetail(data.dialogContent)">
  <img src="/assets/img/add-circle-orange.png" />
  Read more
</button>
