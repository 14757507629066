import { Component, HostBinding } from '@angular/core';
import { ButtonDirective } from '../../directives/button.directive';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SiteMapService } from '../../services/site-map.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  standalone: true,
  imports: [ButtonDirective, CommonModule, RouterModule],
})
export class HeaderComponent {
  public mobileMenuOpen = false;
  public whereWeCanHelpOpen = false;

  @HostBinding('style') get styles() {
    return {
      borderBottomLeftRadius: this.mobileMenuOpen ? '0' : '8px',
      borderBottomRightRadius: this.mobileMenuOpen ? '0' : '8px',
    };
  }

  constructor(public siteMap: SiteMapService) {}

  navTo(path: string) {
    window.location.assign(path);
  }
}
