import { Directive, HostBinding, Input, signal } from '@angular/core';

@Directive({
  selector: '[appButton]',
  standalone: true,
})
export class ButtonDirective {
  @Input() color:
    | 'primary'
    | 'white'
    | 'light-green'
    | 'outline-orange'
    | 'trans-white' = 'primary';
  @HostBinding('style') get styles() {
    return {
      display: 'flex',
      gap: '18px',
      padding: '8px 24px 8px 8px',
      borderRadius: '40px',
      alignItems: 'center',
      color: this.colors[this.color],
      backgroundColor: this.backgroundColors[this.color],
      width: 'fit-content',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '500',
      lineHeight: '26px',
      letterSpacing: '0em',
      border: this.borders[this.color],
      textAlign: 'left',
      textWrap: 'nowrap'
    };
  }

  backgroundColors = {
    'light-green': '#3E83641A',
    primary: '#BD583F',
    white: 'white',
    'outline-orange': 'white',
    'trans-white': '#FFFFFF1A',
  };

  colors = {
    primary: '#FFFFFF',
    white: '#1E3E30',
    'light-green': '#1E3E30',
    'outline-orange': '#1E3E30',
    'trans-white': 'white',
  };

  borders = {
    primary: 'none',
    white: 'none',
    'light-green': 'none',
    'outline-orange': '2px solid #BD583F',
    'trans-white': 'none',
  };

  constructor() {}
}
