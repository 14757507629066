<h2 appTitle accent="science" accentColor="#3e8364">
  The science behind Revive
</h2>
<div class="title"></div>
<p>
  <strong>
    Everything we do is based on using the most up to date scientific data.
  </strong>
  <br />
  We combine cutting-edge biochemistry research with your unique genetic code,
  to give you a comprehensive explanation of the "why" behind our
  recommendations. These insights pave the way for you to conduct your own
  research to learn more about your individuality and discover additional areas
  to optimise your health.
</p>
