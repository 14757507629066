import { Component } from '@angular/core';
import { TitleDirective } from '../../directives/title.directive';

@Component({
  selector: 'app-title-and-text-section',
  standalone: true,
  imports: [TitleDirective],
  templateUrl: './title-and-text-section.component.html',
  styleUrl: './title-and-text-section.component.scss'
})
export class TitleAndTextSectionComponent {

}
