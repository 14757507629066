import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-expansion-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './expansion-card.component.html',
  styleUrl: './expansion-card.component.scss',
})
export class ExpansionCardComponent {
  @Input() title!: string;
  @Input() open = false;
  @Input() htmlContent!: string;

  @HostBinding('style') get styles() {
    return {
      backgroundColor: this.open ? '#eef3e3' : 'white',
    };
  }
}
