import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AreaOverviewComponent } from './pages/area-overview/area-overview.component';
import { SpecificAreaComponent } from './pages/specific-area/specific-area.component';
import { chronicPainData } from './data/chronic-pain/chronic-pain.data';
import { jointPainData } from './data/chronic-pain/joint-pain.data';
import { migraineData } from './data/chronic-pain/migraine.data';
import { optimiseData } from './data/optimise/optimise.data';
import { backPainData } from './data/chronic-pain/back-pain.data';
import { intestinalPainData } from './data/chronic-pain/intestinal-pain.data';
import { nutritionData } from './data/optimise/metabolic-health.data';
import { mentalHealthData } from './data/optimise/mental-health.data';
import { improveMyImmunityData } from './data/optimise/improve-my-immunity.data';
import { improveMySleepData } from './data/optimise/improve-my-sleep.data';
import { gutHealthData } from './data/optimise/gut-health.data';
import { increaseMyEnergyData } from './data/optimise/increase-my-energy.data';
import { LayoutComponent } from './components/layout/layout.component';
import { HowItWorksComponent } from './pages/how-it-works/how-it-works.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { FaqsComponent } from './pages/faqs/faqs.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'how-it-works',
        component: HowItWorksComponent,
      },
      {
        path: 'about-us',
        component: AboutUsComponent,
      },
      {
        path: 'faqs',
        component: FaqsComponent,
      },
      {
        path: 'contact',
        component: ContactUsComponent,
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
      },
      {
        path: 'terms-and-conditions',
        component: TermsAndConditionsComponent,
      },
      {
        path: 'chronic-pain',
        component: AreaOverviewComponent,
        data: chronicPainData,
      },
      // {
      //   path: 'chronic-pain/joint-pain',
      //   component: SpecificAreaComponent,
      //   data: jointPainData,
      // },
      // {
      //   path: 'chronic-pain/migraines',
      //   component: SpecificAreaComponent,
      //   data: migraineData,
      // },
      // {
      //   path: 'chronic-pain/back-pain',
      //   component: SpecificAreaComponent,
      //   data: backPainData,
      // },
      // {
      //   path: 'chronic-pain/intestinal-pain',
      //   component: SpecificAreaComponent,
      //   data: intestinalPainData,
      // },
      {
        path: 'optimise',
        component: AreaOverviewComponent,
        data: optimiseData,
      },
      {
        path: 'optimise/nutrition',
        component: SpecificAreaComponent,
        data: nutritionData,
      },
      {
        path: 'optimise/mental-health',
        component: SpecificAreaComponent,
        data: mentalHealthData,
      },
      {
        path: 'optimise/improve-my-sleep',
        component: SpecificAreaComponent,
        data: improveMySleepData,
      },
      {
        path: 'optimise/improve-my-immunity',
        component: SpecificAreaComponent,
        data: improveMyImmunityData,
      },
      {
        path: 'optimise/gut-health',
        component: SpecificAreaComponent,
        data: gutHealthData,
      },
      {
        path: 'optimise/increase-my-energy',
        component: SpecificAreaComponent,
        data: increaseMyEnergyData,
      },
    ],
  },
];
