<div class="grid-container">
  <div class="branding">
    <img class="logo" src="/assets/img/logo-white.svg" />
    <p class="color-white">Precision nutrition powered by AI</p>
    <div class="socials">
      <img src="/assets/img/socials/facebook.svg" />
      <img src="/assets/img/socials/linkedin.svg" />
      <img src="/assets/img/socials/x.svg" />
    </div>
  </div>

  <div class="site-map">
    <div *ngFor="let section of siteMap.siteMap.sections" class="link-block">
      <div class="header color-white">{{ section.title }}</div>
      <a
        *ngFor="let link of section.links"
        class="color-white"
        [routerLink]="link.routerLink"
        >{{ link.title }}</a
      >
    </div>
    <div class="link-block">
      <div class="header color-white">{{ siteMap.siteMap.company.title }}</div>
      <a
        *ngFor="let link of siteMap.siteMap.company.links"
        [routerLink]="link.routerLink"
        class="color-white"
        >{{ link.title }}</a
      >
    </div>
  </div>
</div>

<hr style="margin: 48px 0 40px" />
<div class="bottom">
  <div class="bottom-links">
    <a [routerLink]="['/terms-and-conditions']">Terms & conditions</a>
    <a [routerLink]="['/privacy-policy']">Privacy Policy</a>
  </div>
  <div class="copyright">Copyright ©Progress Health and Wellness LTD 2024</div>
</div>
