<app-hero></app-hero>
<app-cards-section></app-cards-section>
<app-cards-grid></app-cards-grid>
<app-cards-grid-two></app-cards-grid-two>
<app-pricing-section style="z-index: +1"></app-pricing-section>
<app-image-and-text-card-section
  imageSrc="/assets/img/image-card-2.jpg"
  style="margin-top: -40px"
>
  <h2 appTitle accent="science" accentColor="#3e8364" style="max-width: 290px">
    The science behind Revive
  </h2>
  <div class="title"></div>
  <p style="color: #1e3e3099">
    <strong style="color: #1e3e30">
      Everything we do is based on using the most up to date scientific data.
    </strong>
    <br />
    We combine cutting-edge biochemistry research with your unique genetic code,
    to give you a comprehensive explanation of the "why" behind our
    recommendations. These insights pave the way for you to conduct your own
    research to learn more about your individuality and discover additional
    areas to optimise your health.
  </p>
</app-image-and-text-card-section>
<app-expansion-cards-section
  style="margin-top: -40px; z-index: +1"
></app-expansion-cards-section>
<app-get-started-section></app-get-started-section>
