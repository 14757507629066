<section>
  <h2 appTitle accent="Why" accentColor="#3e8364" class="text-center">
    Why use Revive
  </h2>
</section>

<div class="cards">
  <app-benefit-card
    headerImgSrc="/assets/img/benefits/1.png"
    title="Reset"
    content="Revive your immunity with our 8 week immunity reset programme"
  ></app-benefit-card>
  <app-benefit-card
    headerImgSrc="/assets/img/benefits/2.png"
    title="Nutrition"
    content="Never worry again about what to eat. Our meal planner takes the stress out of meal time."
  ></app-benefit-card>
  <app-benefit-card
    headerImgSrc="/assets/img/benefits/3.png"
    title="Motivation"
    content="Daily Goals to introduce healthy habits into your routine"
  ></app-benefit-card>
  <app-benefit-card
    headerImgSrc="/assets/img/benefits/4.png"
    title="Tracking"
    content="Syncs with Fitbit to keep track of your health"
  ></app-benefit-card>
  <app-benefit-card
    headerImgSrc="/assets/img/benefits/5.png"
    title="Genetics"
    content="Learn the secrets of your unique genetic code"
  ></app-benefit-card>
  <app-benefit-card
    headerImgSrc="/assets/img/benefits/6.png"
    title="Education"
    content="Evidence based recommendations to learn skills that last a lifetime"
  ></app-benefit-card>
</div>
<div class="as-featured">
  <div class="subtitle">As featured in</div>
  <div class="images">
    <img src="/assets/img/as-featured/bbc.png" style="width: 75px" />
    <img src="/assets/img/as-featured/medical-news-today.png" />
  </div>
</div>
