<div class="nav-items">
  <a
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    (mouseenter)="overlayOpen = true"
    style="cursor: pointer"
    >Where we can help</a
  >
  <a
    [routerLink]="['/how-it-works']"
    routerLinkActive="active"
    (mouseenter)="overlayOpen = false"
    >How it works</a
  >
  <a
    [routerLink]="['/about-us']"
    routerLinkActive="active"
    (mouseenter)="overlayOpen = false"
    >About us</a
  >
</div>
<img
  src="/assets/img/logo-header.svg"
  class="header-img"
  [routerLink]="['/']"
  (mouseenter)="overlayOpen = false"
/>
<div class="nav-items">
  <a
    [routerLink]="['/faqs']"
    routerLinkActive="active"
    (mouseenter)="overlayOpen = false"
    >FAQs</a
  >
  <a
    [routerLink]="['/contact']"
    routerLinkActive="active"
    (mouseenter)="overlayOpen = false"
    >Contact</a
  >
  <button
    appButton
    (click)="navTo('https://join.revivedna.com')"
    target="_self"
    (mouseenter)="overlayOpen = false"
    class="color-white"
  >
    <img src="/assets/img/arrow-right-circle-white.svg" />
    Get Started
  </button>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="overlayOpen"
>
  <div class="overlay-menu" (mouseleave)="overlayOpen = false">
    <div class="link-section" *ngFor="let section of siteMap.siteMap.sections">
      <h4>{{ section.title }}</h4>
      <ng-container *ngFor="let link of section.links; let i = index">
        <a [routerLink]="link.routerLink" (click)="overlayOpen = false">{{
          link.title
        }}</a>
        <hr *ngIf="i === 0" />
      </ng-container>
    </div>
  </div>
</ng-template>
