<section>
  <h2 appTitle accent="how" accentColor="#3e8364" class="text-center">
    So how do we do it
  </h2>
</section>

<div class="cards">
  <ng-container *ngFor="let card of cards">
    <app-text-card *ngIf="card.type === 'text'">
      <h3
        title
        appTitle
        [accent]="card.titleAccent"
        [dynamicContent]="card.title"
        class="color-white"
        accentColor="white"
      ></h3>
      <img [src]="card.icon" style="margin: auto" />
      <p class="text-center color-white" style="opacity: 0.8">
        {{ card.content }}
      </p>
    </app-text-card>

    <app-image-card
      *ngIf="card.type === 'image'"
      [backgroundImage]="card.backgroundImage || ''"
      [imageSrc]="card.imageSrc || ''"
    >
    </app-image-card>
  </ng-container>
</div>

<button appButton color="light-green" [routerLink]="['/how-it-works']">
  Find out how it works in detail
</button>
