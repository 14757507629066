import { Component } from '@angular/core';
import { GetStartedSectionComponent } from '../../components/get-started-section/get-started-section.component';
import { ContentCardComponent } from '../../components/content-card/content-card.component';
import { HeroTextOnlyComponent } from '../../components/hero-text-only/hero-text-only.component';
import { TitleDirective } from '../../directives/title.directive';

@Component({
  selector: 'app-terms-and-conditions',
  standalone: true,
  imports: [
    HeroTextOnlyComponent,
    TitleDirective,
    ContentCardComponent,
    GetStartedSectionComponent,
  ],
  templateUrl: './terms-and-conditions.component.html',
  styleUrl: './terms-and-conditions.component.scss',
})
export class TermsAndConditionsComponent {}
