<app-hero-text-only>
  <h1 header class="color-white" appTitle accent="works" accentColor="white">
    How it works
  </h1>
  <p class="color-white">
    The first 8 weeks of revive is focused on looking to improve your immunity.
    A robust immune system is central to optimal health and this is achieved
    predominantly by using the functional properties of food. Our tailored plan
    is designed to give your immune system the support it needs to support your
    body so as to be able to absorb the key nutrients your body needs to thrive.
  </p>
</app-hero-text-only>
<app-content-card>
  <div class="overview-wrapper">
    <h1>Overview</h1>
    <div class="overview-content">
      <p>
        Although we are all genetically different, a focus on gut health should
        always your first point of focus. With a strong digestive system your
        body is better placed to be able to support your path to optimal health.
        One key molecule we target in our reset phase is something called
        Lipopolysaccharide.
      </p>
      <hr />
      <p>Scroll to:</p>
      <div class="scroll-to-section">
        <div class="scroll-to-button">
          <img src="assets/icons/scroll-to.svg" />
          <span appTitle accent="Phase 1" (click)="scrollTo(reset)"
            >Phase 1 - Reset</span
          >
        </div>
        <div class="scroll-to-button">
          <img src="assets/icons/scroll-to.svg" />
          <span appTitle accent="Phase 2" (click)="scrollTo(reinforce)"
            >Phase 2 - Reinforce</span
          >
        </div>
        <div class="scroll-to-button">
          <img src="assets/icons/scroll-to.svg" />
          <span appTitle accent="Phase 3" (click)="scrollTo(rebalance)"
            >Phase 3 - Rebalance</span
          >
        </div>
        <div class="scroll-to-button">
          <img src="assets/icons/scroll-to.svg" />
          <span appTitle accent="Phase 4" (click)="scrollTo(renourish)"
            >Phase 4 - Renourish</span
          >
        </div>
      </div>
    </div>
  </div>
</app-content-card>
<app-image-card backgroundImage="assets/img/food-2.png" maskOpacity=".4">
  <h2 #reset appTitle accent="Phase 1" accentColor="white">Phase 1</h2>
  <h2>Reset</h2>
</app-image-card>
<app-content-card class="section-content">
  <section>
    <div class="text">
      <h4>
        Lipopolysaccharide (LPS) is a potent trigger of inflammation in the
        body, playing a crucial role in the immune response.
      </h4>
      <p>
        Managing and reducing LPS levels is essential for promoting overall
        health, and our targeted approach involves temporarily restricting
        glucose and adopting an anti-inflammatory diet.
      </p>
      <p>
        LPS, primarily found in the outer membrane of Gram-negative bacteria,
        may activate the immune system's inflammatory pathways. Elevated levels
        of LPS can interfere with our immune system and, in some cases may lead
        to chronic inflammation, contributing to various health issues such as
        autoimmune diseases, metabolic disorders, and neurodegenerative
        conditions.
      </p>
    </div>
    <img
      src="assets/img/how-it-works/how-it-works-1.png"
      class="article-image"
    />
  </section>
  <app-quote>
    <h3>
      “Addressing LPS-induced inflammation is critical for creating an
      environment conducive to the body's repair mechanisms.”
    </h3>
  </app-quote>
  <section class="reverse">
    <div class="text">
      <h4>
        One strategy to reduce LPS is the temporary restriction of glucose.
      </h4>
      <p>
        Glucose metabolism is closely linked to inflammation, and restricting
        its availability can have profound effects on immune system modulation.
      </p>
      <p>
        During glucose restriction, the body shifts its energy metabolism
        towards alternative pathways, such as ketogenesis. This shift not only
        helps in reducing LPS levels but also promotes cellular repair and
        regeneration.
      </p>
    </div>
    <img
      src="assets/img/how-it-works/how-it-works-2.png"
      class="article-image"
    />
  </section>
  <section>
    <div class="text">
      <h4>Using food to reduce inflammation</h4>
      <p>
        Furthermore, adopting a diet rich in molecules that mitigate
        inflammation is crucial. Certain foods and dietary components possess
        anti-inflammatory properties, helping to counteract the effects of LPS.
        Omega-3 fatty acids, found in fish oil, flaxseeds, and walnuts, are
        known for their anti-inflammatory effects. Polyphenols, abundant in
        fruits, vegetables, and green tea, also exhibit anti-inflammatory
        properties. These dietary choices contribute to a healthier balance in
        the body's inflammatory response.
      </p>
    </div>
    <img
      src="assets/img/how-it-works/how-it-works-3.png"
      class="article-image"
    />
  </section>
</app-content-card>
<app-image-card
  backgroundImage="assets/img/how-it-works/reinforce.png"
  maskOpacity=".4"
>
  <h2 #reinforce appTitle accent="Phase 2" accentColor="white">Phase 2</h2>
  <h2>Reinforce</h2>
</app-image-card>
<app-content-card class="section-content">
  <section>
    <div class="text">
      <h4>
        The next phase of your journey consists of reinforcing your gut
        microbiota - the complex community of microorganisms residing in your
        digestive system.
      </h4>
      <p>
        Our service has a particular focus on two key commensal bacteria
        strains, Bifidobacteria and Akkermansia Muciniphila.
      </p>
    </div>
    <img
      src="assets/img/how-it-works/how-it-works-4.png"
      class="article-image"
    />
  </section>
  <app-quote>
    <h3>
      Your gut is home to a bustling community of microorganisms, collectively
      known as the gut microbiota. This intricate symphony of bacteria, viruses,
      fungi, and other microorganisms plays a fundamental role in various
      aspects of health, from digestion to immune function. Reinforcing this
      microbiota is crucial for maintaining a balanced and resilient gut
      environment
    </h3>
  </app-quote>
  <section class="reverse">
    <div class="text">
      <h4>Bifidobacteria: The Guardians of Gut Harmony</h4>
      <p>
        Bifidobacteria stand out as pivotal guardians in your gut. These
        beneficial bacteria play a crucial role in fermenting dietary fibres,
        producing short-chain fatty acids, and creating an environment that
        discourages the growth of harmful pathogens. By reinforcing
        Bifidobacteria, we may foster a harmonious gut environment, supporting
        digestion, nutrient absorption, and overall gastrointestinal health.
      </p>
    </div>
    <img
      src="assets/img/how-it-works/how-it-works-5.png"
      class="article-image"
    />
  </section>
  <!-- <section>
    <div class="text">
      <h4>Using food to reduce inflammation</h4>
      <p>
        Adopting a diet rich in molecules that mitigate inflammation is crucial.
        Certain foods and dietary components possess anti-inflammatory
        properties, helping to counteract the effects of LPS. Omega-3 fatty
        acids, found in fish oil, flaxseeds, and walnuts, are known for their
        anti-inflammatory effects. Polyphenols, abundant in fruits, vegetables,
        and green tea, also exhibit anti-inflammatory properties. These dietary
        choices contribute to a healthier balance in the body's inflammatory
        response.
      </p>
    </div>
    <img
      src="assets/img/how-it-works/how-it-works-2.png"
      class="article-image"
    />
  </section> -->
  <section>
    <div class="text">
      <h4>Akkermansia muciniphila: The Mucin Magician:</h4>
      <p>
        Akkermansia muciniphila emerges as another key player in the world of
        gut health. This unique bacterium specializes in breaking down mucins in
        the protective layer that lines the gut. By maintaining mucin integrity,
        Akkermansia muciniphila contributes to a healthy gut barrier, preventing
        the infiltration of harmful substances and promoting a balanced immune
        response. Reinforcing this strain becomes instrumental in fortifying the
        gut's defence mechanisms.
      </p>
    </div>
    <img
      src="assets/img/how-it-works/how-it-works-6.png"
      class="article-image"
    />
  </section>
  <app-info-card>
    <h4 style="color: #3e8364">The Importance of Reinforcement</h4>
    <p style="color: #1e3e30">
      Reinforcing your gut microbiota is not just a matter of balance; it's a
      proactive step toward overall well-being. With a diverse and resilient
      microbiota you will likely be better equipped to handle challenges, resist
      disturbances, and adapt to changes in diet and lifestyle. By focusing on
      key strains like Bifidobacteria and Akkermansia muciniphila, we can take a
      targeted approach to strengthen the foundations of gut health.
    </p>
  </app-info-card>
</app-content-card>
<app-image-card
  backgroundImage="assets/img/how-it-works/rebalance.png"
  maskOpacity=".4"
>
  <h2 #rebalance appTitle accent="Phase 3" accentColor="white">Phase 3</h2>
  <h2>Rebalance</h2>
</app-image-card>
<app-content-card class="section-content">
  <section>
    <div class="text">
      <h4>
        The third phase of our service focuses on rebalancing key processes in
        your body.
      </h4>
      <p>
        At the core of our philosophy is the understanding that nutrient
        rebalancing forms the foundation for unlocking your body's full
        potential. We go beyond traditional dietary approaches, emphasizing the
        importance of a harmonious blend of essential nutrients tailored to your
        needs by taking into account your unique DNA. We are not so interested
        here in tracking macros and calories, but more looking to increase your
        intake of nutrients that your body needs based on your genetic analysis.
        We believe that this precision nutrition approach grounded in science
        will revolutionalise health and wellness.
      </p>
    </div>
    <img
      src="assets/img/how-it-works/how-it-works-7.png"
      class="article-image"
    />
  </section>
  <app-quote>
    <h3>
      Your gut is home to a bustling community of microorganisms, collectively
      known as the gut microbiota. This intricate symphony of bacteria, viruses,
      fungi, and other microorganisms plays a fundamental role in various
      aspects of health, from digestion to immune function. Reinforcing this
      microbiota is crucial for maintaining a balanced and resilient gut
      environment.
    </h3>
  </app-quote>
  <section class="reverse">
    <div class="text">
      <h4>Harmonizing Hormones for Holistic Wellness</h4>
      <p>
        We also take a holistic view of health, recognizing the crucial role of
        hormonal balance. We focus on the equilibrium between leptin and
        ghrelin, the regulators of hunger and satiety, and address the delicate
        balance of cortisol, the stress hormone, and melatonin, the sleep
        hormone, offering insights and recommendations to foster equilibrium for
        improved daily functioning and tips for better sleep.
      </p>
    </div>
    <img
      src="assets/img/how-it-works/how-it-works-8.png"
      class="article-image"
    />
  </section>
  <section>
    <div class="text">
      <h4>Environmental Synchronicity: A Vital Element</h4>
      <p>
        Wellness extends beyond individual choices; it encompasses harmony with
        the environment. Our platform underscores the importance of being in
        balance with the world around you. By aligning your routines with
        natural circadian rhythms and optimizing your surroundings, we empower
        you with the knowledge to synchronize with the environment. This
        approach not only enhances your overall well-being but also nurtures a
        profound connection with the world around you.
      </p>
    </div>
    <img
      src="assets/img/how-it-works/how-it-works-9.png"
      class="article-image"
    />
  </section>
  <app-info-card>
    <h4 style="color: #3e8364">Empowering Your Journey</h4>
    <p style="color: #1e3e30">
      Our commitment is to empower you on your wellness journey. By
      understanding the intricacies of nutrient rebalancing, hormonal
      equilibrium, and environmental synchronicity, we provide personalized
      recommendations that go beyond generic health advice. Through our
      platform, embark on a transformative path towards better health outcomes,
      fostering vitality, balance, and a harmonious connection with your body
      and surroundings.
    </p>
  </app-info-card>
</app-content-card>
<app-image-card
  backgroundImage="assets/img/how-it-works/renourish.jpeg"
  maskOpacity=".4"
>
  <h2 #renourish appTitle accent="Phase 4" accentColor="white">Phase 4</h2>
  <h2>Renourish</h2>
</app-image-card>
<app-content-card class="section-content" style="margin-bottom: 0">
  <section>
    <div class="text">
      <h4>
        Understanding your unique DNA is the gateway to unlocking a healthier,
        more vibrant you.
      </h4>
      <p>
        Revive is dedicated to illuminating the importance of your genetic code
        and the crucial nutrients your body craves. By delving into 300 million
        DNA positions and aligning with cutting-edge research, we guide you
        through a journey of self-discovery, revealing inherent strengths and
        vulnerabilities.
      </p>
    </div>
    <img
      src="assets/img/how-it-works/how-it-works-10.png"
      class="article-image"
    />
  </section>
  <app-quote>
    <h3>
      Revive transforms this knowledge into a personalized culinary adventure.
      As you engage with our platform, it learns from your preferences,
      delivering meals designed to cater to your unique genetic makeup. This
      intelligent adaptation ensures you not only enjoy your meals but also
      provide your cells with the essential vitamins and minerals they require
      to thrive
    </h3>
  </app-quote>
  <section class="reverse">
    <div class="text">
      <h4>Unlock the full potential of your unique DNA</h4>
      <p>
        Feeding your cells with precision nutrition becomes a seamless process,
        bridging the gap between genetic insights and daily nourishment.
        Personalized well-being is not just a goal but a tangible reality,
        tailored to the intricacies of your genetic fingerprint. During this
        phase you will embrace the power of personalized nutrition and unlock
        the full potential of your unique DNA.
      </p>
    </div>
    <img
      src="assets/img/how-it-works/how-it-works-11.png"
      class="article-image"
    />
  </section>
</app-content-card>
<app-get-started-section></app-get-started-section>
