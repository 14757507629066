import {
  AfterViewChecked,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[appTitle]',
  standalone: true,
})
export class TitleDirective implements AfterViewChecked {
  @Input() accent?: string;
  @Input() accentColor = '#1e3e30';
  @Input() dynamicContent?: string;

  constructor(private elRef: ElementRef, private renderer: Renderer2) {}

  ngAfterViewChecked(): void {
    this.renderer.setProperty(
      this.elRef.nativeElement,
      'innerHTML',
      this.transformText(
        this.dynamicContent || this.elRef.nativeElement.innerText,
        this.accent
      )
    );
  }

  private transformText(innerText: string, accent?: string) {
    if (accent) {
      return innerText.replace(
        accent,
        `<span class="italic" style="color: ${this.accentColor}; font-weight: 400">${this.accent}</span>`
      );
    }
    return innerText;
  }
}
