export const increaseMyEnergyData = {
  title: 'Increase my energy',
  titleAccent: 'energy',
  heroContent:
    'Revive harnesses the power of DNA analysis to offer personalised insights tailored to individual needs, with a primary focus on supporting energy levels. By analysing genetic markers related to metabolism, nutrient absorption, and food sensitivities, Revive provides users with actionable recommendations to optimise their diet for increased vitality.',
  areaOverview: {
    title: 'Overview',
    contentSections: [
      'Through comprehensive DNA analysis, Revive identifies specific nutrient deficiencies or intolerances that may be impacting energy levels. For instance, it can pinpoint whether a person may benefit from more iron-rich foods or if they should reduce their intake of certain carbohydrates to avoid energy crashes.',
      'Additionally, the app factors in lifestyle variables such as activity levels, sleep patterns, and stress levels to offer holistic recommendations. It suggests customized meal plans, designed to replenish energy reserves and support sustained vitality throughout the day.',
      'Moreover, Revive fosters long-term behaviour change by tracking progress and adjusting meal recommendations based on user feedback. By empowering individuals to make informed choices aligned with their unique genetic makeup, Revive revolutionises the way people approach nutrition, helping them unlock their full energy potential and thrive in their daily lives.',
    ],
  },
  signUp: {
    content:
      'We send regular free tips and the latest scientific advice on how to help manage your ongoing symptoms.',
    options: [
      'Nutrition',
      'Mental health',
      'Sleep',
      'Immunity',
      'Gut health',
      'Energy',
    ],
    imgSrc: '/assets/img/how-we-can-help-2.png',
  },
};
