import { Component, Input } from '@angular/core';
import { InfoCardComponent } from './info-card/info-card.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-info-cards-section',
  standalone: true,
  imports: [InfoCardComponent, CommonModule],
  templateUrl: './info-cards-section.component.html',
  styleUrl: './info-cards-section.component.scss',
})
export class InfoCardsSectionComponent {
  @Input() cards?: any[] | null;
}
