import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TitleDirective } from '../../directives/title.directive';
import {
  PhaseCardComponent,
  PhaseCardData,
} from './phase-card/phase-card.component';

@Component({
  selector: 'app-phase-cards-section',
  standalone: true,
  imports: [CommonModule, TitleDirective, PhaseCardComponent],
  templateUrl: './phase-cards-section.component.html',
  styleUrl: './phase-cards-section.component.scss',
})
export class PhaseCardsSectionComponent {
  public cards: PhaseCardData[] = [
    {
      small: 'Estimated time: 4 weeks',
      indicatorOpacity: '.2',
      title: 'Phase 1 Reset',
      titleAccent: 'Reset',
      content:
        'We start by focusing on support to help reset your immune function',
      dialogContent: {
        title: 'Phase 1 Reset',
        titleAccent: 'Reset',
        content: [
          {
            type: 'h4',
            content:
              'Lipopolysaccharide (LPS) is a potent trigger of inflammation in the body, playing a crucial role in the immune response.',
          },
          {
            type: 'p',
            content:
              'Managing and reducing LPS levels is essential for promoting overall health, and our targeted approach involves temporarily restricting glucose and adopting an anti-inflammatory diet.',
          },
          {
            type: 'p',
            content:
              "LPS, primarily found in the outer membrane of Gram-negative bacteria, may activate the immune system's inflammatory pathways. Elevated levels of LPS can interfere with our immune system and, in some cases may lead to chronic inflammation, contributing to various health issues such as autoimmune diseases, metabolic disorders, and neurodegenerative conditions.",
          },
          {
            type: 'h4',
            content:
              'One strategy to reduce LPS is the temporary restriction of glucose.',
          },
          {
            type: 'p',
            content:
              'Glucose metabolism is closely linked to inflammation, and restricting its availability can have profound effects on immune system modulation.',
          },
          {
            type: 'p',
            content:
              'During glucose restriction, the body shifts its energy metabolism towards alternative pathways, such as ketogenesis. This shift not only helps in reducing LPS levels but also promotes cellular repair and regeneration.',
          },
          {
            type: 'h4',
            content: 'Using food to reduce inflammation',
          },
          {
            type: 'p',
            content:
              "Furthermore, adopting a diet rich in molecules that mitigate inflammation is crucial. Certain foods and dietary components possess anti-inflammatory properties, helping to counteract the effects of LPS. Omega-3 fatty acids, found in fish oil, flaxseeds, and walnuts, are known for their anti-inflammatory effects. Polyphenols, abundant in fruits, vegetables, and green tea, also exhibit anti-inflammatory properties. These dietary choices contribute to a healthier balance in the body's inflammatory response.",
          },
        ],
      },
    },
    {
      small: 'Estimated time: 4 weeks',
      indicatorOpacity: '.3',
      title: 'Phase 2 Reinforce',
      titleAccent: 'Reinforce',
      content:
        'The gut lining is designed to be our first line of defence against the outside world and should be treated as one of the most important areas of your body.',
      dialogContent: {
        title: 'Phase 2 Reinforce',
        titleAccent: 'Reinforce',
        content: [
          {
            type: 'h4',
            content:
              'The next phase of your journey consists of reinforcing your gut microbiota - the complex community of microorganisms residing in your digestive system.',
          },
          {
            type: 'p',
            content:
              'Our service has a particular focus on two key commensal bacteria strains, Bifidobacteria and Akkermansia Muciniphila.',
          },
          {
            type: 'h4',
            content: 'Bifidobacteria: The Guardians of Gut Harmony',
          },
          {
            type: 'p',
            content:
              'Bifidobacteria stand out as pivotal guardians in your gut. These beneficial bacteria play a crucial role in fermenting dietary fibres, producing short-chain fatty acids, and creating an environment that discourages the growth of harmful pathogens. By reinforcing Bifidobacteria, we may foster a harmonious gut environment, supporting digestion, nutrient absorption, and overall gastrointestinal health.',
          },
          {
            type: 'h4',
            content: 'Akkermansia muciniphila: The Mucin Magician:',
          },
          {
            type: 'p',
            content:
              "Akkermansia muciniphila emerges as another key player in the world of gut health. This unique bacterium specializes in breaking down mucins in the protective layer that lines the gut. By maintaining mucin integrity, Akkermansia muciniphila contributes to a healthy gut barrier, preventing the infiltration of harmful substances and promoting a balanced immune response. Reinforcing this strain becomes instrumental in fortifying the gut's defence mechanisms.",
          },
          {
            type: 'h4',
            content: 'The Importance of Reinforcement',
          },
          {
            type: 'p',
            content:
              "Reinforcing your gut microbiota is not just a matter of balance; it's a proactive step toward overall well-being. With a diverse and resilient microbiota you will likely be better equipped to handle challenges, resist disturbances, and adapt to changes in diet and lifestyle. By focusing on key strains like Bifidobacteria and Akkermansia muciniphila, we can take a targeted approach to strengthen the foundations of gut health.",
          },
        ],
      },
    },
    {
      small: 'Estimated time: 4 weeks',
      indicatorOpacity: '.7',
      title: 'Phase 3 Rebalance',
      titleAccent: 'Rebalance',
      content:
        'Once gut integrity is improved we focus on key nutrients that your body needs more of to support improved function',
      dialogContent: {
        title: 'Phase 3 Rebalance',
        titleAccent: 'Rebalance',
        content: [
          {
            type: 'h4',
            content:
              'The third phase of our service focuses on rebalancing key processes in your body.',
          },
          {
            type: 'p',
            content:
              "At the core of our philosophy is the understanding that nutrient rebalancing forms the foundation for unlocking your body's full potential. We go beyond traditional dietary approaches, emphasizing the importance of a harmonious blend of essential nutrients tailored to your needs by taking into account your unique DNA. We are not so interested here in tracking macros and calories, but more looking to increase your intake of nutrients that your body needs based on your genetic analysis. We believe that this precision nutrition approach grounded in science will revolutionalise health and wellness.",
          },
          {
            type: 'h4',
            content: 'Harmonizing Hormones for Holistic Wellness',
          },
          {
            type: 'p',
            content:
              'We also take a holistic view of health, recognizing the crucial role of hormonal balance. We focus on the equilibrium between leptin and ghrelin, the regulators of hunger and satiety, and address the delicate balance of cortisol, the stress hormone, and melatonin, the sleep hormone, offering insights and recommendations to foster equilibrium for improved daily functioning and tips for better sleep.',
          },
          {
            type: 'h4',
            content: 'Environmental Synchronicity: A Vital Element',
          },
          {
            type: 'p',
            content:
              'Wellness extends beyond individual choices; it encompasses harmony with the environment. Our platform underscores the importance of being in balance with the world around you. By aligning your routines with natural circadian rhythms and optimizing your surroundings, we empower you with the knowledge to synchronize with the environment. This approach not only enhances your overall well-being but also nurtures a profound connection with the world around you.',
          },
          {
            type: 'h4',
            content: 'Empowering Your Journey',
          },
          {
            type: 'p',
            content:
              'Our commitment is to empower you on your wellness journey. By understanding the intricacies of nutrient rebalancing, hormonal equilibrium, and environmental synchronicity, we provide personalized recommendations that go beyond generic health advice. Through our platform, embark on a transformative path towards better health outcomes, fostering vitality, balance, and a harmonious connection with your body and surroundings.',
          },
        ],
      },
    },
    {
      small: 'Lasts a lifetime',
      indicatorOpacity: '1',
      title: 'Phase 4 Renourish',
      titleAccent: 'Renourish',
      content:
        'Our personalised recipes are tailored to help support you for the rest of your life',
      dialogContent: {
        title: 'Phase 4 Renourish',
        titleAccent: 'Renourish',
        content: [
          {
            type: 'h4',
            content:
              'Understanding your unique DNA is the gateway to unlocking a healthier, more vibrant you.',
          },
          {
            type: 'p',
            content:
              'Revive is dedicated to illuminating the importance of your genetic code and the crucial nutrients your body craves. By delving into 300 million DNA positions and aligning with cutting-edge research, we guide you through a journey of self-discovery, revealing inherent strengths and vulnerabilities.',
          },
          {
            type: 'h4',
            content: 'Unlock the full potential of your unique DNA',
          },
          {
            type: 'p',
            content:
              'Feeding your cells with precision nutrition becomes a seamless process, bridging the gap between genetic insights and daily nourishment. Personalized well-being is not just a goal but a tangible reality, tailored to the intricacies of your genetic fingerprint. During this phase you will embrace the power of personalized nutrition and unlock the full potential of your unique DNA.',
          },
        ],
      },
    },
  ];
}
