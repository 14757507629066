import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { ButtonDirective } from '../../../directives/button.directive';
import { OverlayModule } from '@angular/cdk/overlay';
import { SiteMapService } from '../../../services/site-map.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-desktop-header',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    ButtonDirective,
    OverlayModule,
    CommonModule,
  ],
  templateUrl: './desktop-header.component.html',
  styleUrl: './desktop-header.component.scss',
})
export class DesktopHeaderComponent {
  overlayOpen = false;

  constructor(public siteMap: SiteMapService) {}

  navTo(path: string) {
    window.location.assign(path);
  }
}
