import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-text-long-form',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './text-long-form.component.html',
  styleUrl: './text-long-form.component.scss'
})
export class TextLongFormComponent {

  @Input() data!: any;

}
